.tqn-modal {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
}

.tqn-modal-open {
  overflow: hidden;
}