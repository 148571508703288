.tqn-inventory-modal-actionsheet {
  --tqc-inv-modal-tip: var(--tqc-gold);
  --tqc-inv-modal-payment-date: var(--tqc-gold);
  --tqc-inv-modal-title: var(--tqc-gold);
  --tqc-inv-modal-subtitle: var(--tqc-gold);
  --tqc-inv-modal-warning: var(--tqc-gold);

  position: relative;
  flex: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 0 40px 0 rgb(0, 0, 0, 0.7);
  overflow: hidden;

  & .information {
    position: relative;
    text-align: left;
    padding: 15px 20px 20px 20px;

    & .line {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;

      & .label {
        width: 70%;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      & .value {
        flex: 1;
        text-align: right;
        font-weight: 600;
      }

      &.tip {
        color: var(--tqc-inv-modal-tip);
        margin-top: 6px;
        font-weight: 200;
      }

      & .first-listing-free {
        margin-top: 6px;
        text-align: right;
        width: 100%;
      }
    }

    & .payment-date {
      color: var(--tqc-inv-modal-payment-date);
      margin-top: 16px;
    }

    & .pending-payment-info {
      margin-top: 16px;

      & button {
        display: inline-flex;
      }
    }

    & .actions {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      & .tqn-button {
        flex: 1;
        max-width: 60%;

        & > button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
        }
      }

      & .submit {
        flex: 1.5;
      }
    }
  }

  & .tqn-inventory-card {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid var(--tqc-horizontal-line-dark);
    box-shadow: none;
    cursor: default;

    & .details-panel .matchup {
      font-size: 18px;
      line-height: 26px;
      padding-top: 0;

      & .at-symbol {
        font-size: 16px;
        top: -2px;
      }
    }

    & .details-panel .seat-info {
      margin-top: 10px;
      margin-bottom: 2px;
    }

    & .details-panel .playoff-game-type {
      margin-top: 8px;
    }

    & .details-panel .playoff-desc .modal-label {
      margin-bottom: 0;
    }
  }

  & .error_listing {
    & .title {
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      color: var(--tqc-inv-modal-title);
      text-align: center;
      margin-bottom: 15px;
    }

    & .description {
      text-align: center;

      & .headline {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 3px;
      }

      & .subtitle {
        color: var(--tqc-inv-modal-subtitle);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        font-size: 14px;
        line-height: 1.33;

        & .bold {
          font-weight: 600;
        }
      }
    }

    & .text {
      text-align: center;
    }

    & .here-btn {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 1.5;
    }
  }

  & .last-minute {
    text-align: left;

    & .content-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & .title {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 6px;
      }

      & .subtitle {
        color: var(--tqc-inv-modal-subtitle);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        font-size: 14px;
        line-height: 22px;

        & .bold {
          font-weight: 600;
        }
      }
    }

    & .warning {
      color: var(--tqc-inv-modal-warning);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      font-size: 12px;
      font-weight: 500;
      font-style: italic;
      line-height: 1.83;
      margin-bottom: 10px;

      & .tqn-color-icon {
        margin-top: -4px;
      }
    }

    & .text {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 18px;
      line-height: 1.83;

      & em {
        font-weight: 700;
      }
    }
  }

  & .left-to-list {
    font-size: 16px;
    line-height: 1.5;
    text-align: right;

    & .tqn-timer-suffix {
      font-size: 14px;
    }
  }

  .details-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 0;
  }

  .icon-lock {
    margin-top: 2px;
  }

  .guaranteed-price-label {
    display: flex;
    gap: 6px;
  }

  .is-hidden {
    opacity: 0;
  }
}
