.tqn-payments {
  --tqc-payments-text: var(--tqc-grey-5);
  --tqc-payments-help-text: var(--tqc-grey-3);
  --tqc-payments-help-label: var(--tqc-white);
  --tqc-payments-download: var(--tqc-gold);

  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  margin-bottom:40px;

  & .download-history button {
    font-size: 12px;
    font-weight: 400;
    height: 26px;
    color: var(--tqc-payments-download);
    border-color: var(--tqc-payments-download);
    width: auto;
    margin: 0 auto 12px;
    padding-left: 30px;
    padding-right: 30px;

    & .tqn-color-icon {
      margin-right: 8px;
    }
  }

  & table {
    width: 100%;

    & .date {
      width: 30%;
    }

    & .amount {
      width: 26%;

      & .tqn-button {
        width: 100%;

        & button {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .btn-text {
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
          }

          .arrow {
            font-size: 16px;
            font-weight: 400;
            line-height: 12px;
          }
        }
      }
    }

    & .confirmation {
      width: 44%;
    }
  }

  & tr.header th {
    padding: 9px 11px;
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid var(--tqc-horizontal-line-light);
  }

  & tbody {
    & td {
      padding: 12px 11px;
      font-size: 12px;
      font-weight: 200;
      text-align: left;
      color: var(--tqc-payments-text);
    }

    & tr:first-child td {
      padding-top: 15px;
    }
  }

  & .help {
    margin-top: 24px;
    margin-bottom: 40px;
    font-size: 12px;
    line-height: 20px;
    color: var(--tqc-payments-help-text);

    & .label {
      font-weight: 600;
      color: var(--tqc-payments-help-label);
    }
  }

  & .page-header {
    position:sticky;
    top: -16px;
    background: var(--tqc-app-bg);
    z-index: 100;
    box-shadow: 0 6px 4px -4px var(--tqc-app-bg)
  }

  & .table-header {
    display: flex;

    div {
      padding: 9px 11px;
      text-align: left;
      font-size: 12px;
      font-weight: 600;
      border-bottom: 1px solid var(--tqc-horizontal-line-light);
    }

    & .date {
      width: 30%;
    }

    & .confirmation {
      width: 44%;
      white-space: nowrap;
    }

    & .amount {
      width: 26%;
    }
  }

  .payment-loading-section {
    height: 60px;

    .lds-spinner {
      position: relative;
      left: 50%;
      height: auto;
    }
  }
}
