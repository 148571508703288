.delete-account {
  position: relative;
  margin-top: -10px;
  padding-bottom: 20px;

  & .delete-account-screen {
    padding-left: 24px;
    padding-right: 24px;

    &.tqn-anim-start {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  & .title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--tqc-gold);
    font-size: 24px;
    font-weight: bold;
    padding: 0 0 12px;
    border-bottom: 1px solid var(--tqc-grey-3);
    margin-bottom: 15px;
  }

  & .feedback-text {
    background: transparent;
    border: 1px solid var(--tqc-grey-4);
    border-radius: 8px;
    box-sizing: border-box;
    outline: none;
    width: 100%;
    color: var(--tqc-white);
    padding: 9px 13px;
    font-family: var(--tqn-poppins);
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  & .tqn-button {
    width: 204px;
    margin: 0 auto;
  }
}
