.tqn-animation-wrapper {
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  & > .tqn-animated-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    & > div {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  & .split-text {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &.animating {
    transition: height 300ms ease-in-out;
  }
}
