.tqn-team-creds-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  border-radius: 10px;
  background-color: var(--tqc-grey-1);

  &.tqn-slide {
    height: 100%;
  }

  & .fields {
    flex: 1;
  }

  & .actions {
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
    margin-top: 25px;

    & .tqn-button {
      flex: 1;
    }
  }

  & .error {
    color: var(--tqc-error);
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    text-align: center;
  }

  & .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 10px;
    margin-top: 8px;
  }

  & .alert-text {
    font-size: 14px;
  }
}
