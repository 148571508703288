.tqn-account-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  overflow: hidden;

  & .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.5;
  }

}
