.tqn-checkbox {
  --tqc-checkbox-default: var(--tqc-white);
  --tqc-checkbox-red: var(--tqc-red);
  --tqc-checkbox-green: var(--tqc-green);
  --tqc-checkbox-white: var(--tqc-white);

  width: 20px;
  height: 20px;
  mask-image: url(/images/icons/Icon-SelectAll-Unchecked.svg);
  mask-size: cover;
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: var(--tqc-checkbox-default);

  &.tqn-green {
    background-color: var(--tqc-checkbox-green);
  }

  &.tqn-red {
    background-color: var(--tqc-checkbox-red);
  }

  &.tqn-white {
    background-color: var(--tqc-checkbox-white);
  }

  &.tqn-checked {
    mask-image: url(/images/icons/Icon-SelectAll.svg);
  }

  &.tqn-single {
    mask-image: url(/images/icons/Icon-Box-Unchecked.svg);

    &.tqn-checked {
      mask-image: url(/images/icons/Icon-Box-Checked.svg);
    }
  }

  &.tqn-disabled {
    opacity: 0.5;
  }
}

.tqn-checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  line-height: 22px;

  & .tqn-checkbox {
    flex: none;
  }

  &.tqn-disabled {
    opacity: 0.5;
  }
}
