.tqn-add-new-tickets {
    & .menu-item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 14px 6px 14px 12px;
        border-bottom: 1px solid var(--tqc-horizontal-line-dark);
        cursor: pointer;

        & .menu-item-row {
            display: flex;
            align-items: center;
            font-size: 16px;

            & .menu-item-label {
                flex: 1;
                line-height: 26px;
            }

            & .beta-badge {
                font-size: 12px;
                font-weight: 600;
                line-height: 26px;
                border-radius: 25px;
                padding: 0 8px;
                margin-left: 10px;
                background: var(--tqc-green);

                &.hidden {
                    opacity: 0;
                    visibility: hidden;
                }
            }

            & .menu-item-arrow {
                flex: none;
            }
        }

        &:last-child {
            border-bottom: none;
        }

        & .menu-item-sublabel {
            font-size: 14px;
        }
    }
}