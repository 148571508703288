.tqn-alert-nav-bottom {
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 10px 12px 10px 14px;
  font-size: 14px;
  line-height: 20px;
  background-color: var(--tqc-grey-2);
  border-radius: 8px;
  cursor: pointer;

  & .tqn-color-icon {
    flex: none;

    &.alert-icon {
      width: 28px;
      height: 28px;
    }
  }
}