/* Common animation classes */

.tqn-slide {
  position: absolute;
  width: 100%;

  &.tqn-anim-end {
    transition: transform 300ms ease-in-out;
  }

  &.tqn-right {
    &.tqn-enter {
      &.tqn-anim-start {
        transform: translateX(100%);
      }

      &.tqn-anim-end {
        transform: translateX(0);
      }
    }

    &.tqn-leave {
      &.tqn-anim-start {
        transform: translateX(0);
      }

      &.tqn-anim-end {
        transform: translateX(100%);
      }
    }
  }

  &.tqn-left {
    &.tqn-enter {
      &.tqn-anim-start {
        transform: translateX(-100%);
      }

      &.tqn-anim-end {
        transform: translateX(0);
      }
    }

    &.tqn-leave {
      &.tqn-anim-start {
        transform: translateX(0);
      }

      &.tqn-anim-end {
        transform: translateX(-100%);
      }
    }
  }

  &.tqn-top {
    &.tqn-enter {
      &.tqn-anim-start {
        transform: translateY(-100%);
      }

      &.tqn-anim-end {
        transform: translateY(0);
      }
    }

    &.tqn-leave {
      &.tqn-anim-start {
        transform: translateY(0);
      }

      &.tqn-anim-end {
        transform: translateY(-100%);
      }
    }
  }

  &.tqn-bottom {
    &.tqn-enter {
      &.tqn-anim-start {
        transform: translateY(100%);
      }

      &.tqn-anim-end {
        transform: translateY(0);
      }
    }

    &.tqn-leave {
      &.tqn-anim-start {
        transform: translateY(0);
      }

      &.tqn-anim-end {
        transform: translateY(100%);
      }
    }
  }

  &.fixed {
    position: fixed;
  }
}

.tqn-fade {
  &.tqn-anim-end {
    transition: opacity 300ms ease-in-out !important;
  }

  &.tqn-enter {
    &.tqn-anim-start {
      opacity: 0 !important;
    }

    &.tqn-anim-end {
      opacity: 1 !important;
    }
  }

  &.tqn-leave {
    &.tqn-anim-start {
      opacity: 1 !important;
    }

    &.tqn-anim-end {
      opacity: 0 !important;
    }
  }
}

.tqn-blur {
  &.tqn-anim-end {
    transition: filter 300ms ease-in-out;
  }

  &.tqn-enter {
    &.tqn-anim-start {
      filter: blur(0);
    }

    &.tqn-anim-end {
      filter: blur(8px);
    }
  }

  &.tqn-leave {
    &.tqn-anim-start {
      filter: blur(8px);
    }

    &.tqn-anim-end {
      filter: blur(0);
    }
  }
}
