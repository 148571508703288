.tqn-radio-group {
  & .option {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 30px;
    cursor: pointer;

    & .icon {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 2px solid #fff;
      position: relative;
    }

    &.selected .icon::after {
      content: ' ';
      display: block;
      width: 10px;
      height: 10px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 3px;
      left: 3px;
    }
  }
}
