.tqn-dashboard {
  --tqc-dashboard-text: var(--tqc-white);
  --tqc-dashboard-slider-pagination-dot: var(--tqc-grey-2);
  --tqc-dashboard-slider-pagination-dot-selected: var(--tqc-gold);
  --tqc-dashboard-slider-arrow: var(--tqc-grey-3);

  padding-bottom: 20px;

  & .slider-title {
    font-size: 22px;
    font-weight: 600;
    color: var(--tqc-dashboard-text);
    margin-top: 10%;
    margin-bottom: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  & .referral-program-section {
    border-bottom: 1px solid var(--tqc-horizontal-line-dark);
    margin-top: 35px;
    padding: 0 16% 35px 16%;
  }

  & .tqn-dashboard-slider {
    height: 180px;
    border-bottom: 1px solid var(--tqc-horizontal-line-dark);
    margin-top: -30px;

    & .splide__track {
      height: 100%;

      & .splide__list {
        position: relative;
        z-index: 0;
      }

      &::before,
      &::after {
        content: ' ';
        display: block;
        width: 4em;
        height: 100%;
        position: absolute;
        top: 0;
        background: red;
        z-index: 1;
      }

      &::before {
        left: 0;
        background: rgb(13, 18, 18);
        background: linear-gradient(
          90deg,
          rgba(13, 18, 18, 1) 0%,
          rgba(13, 18, 18, 1) 70%,
          rgba(13, 18, 18, 0) 100%
        );
      }

      &::after {
        right: 0;
        background: rgb(13, 18, 18);
        background: linear-gradient(
          270deg,
          rgba(13, 18, 18, 1) 0%,
          rgba(13, 18, 18, 1) 70%,
          rgba(13, 18, 18, 0) 100%
        );
      }
    }

    & .splide__slide {
      display: flex;
      align-items: center;
      justify-content: center;

      & span {
        max-width: 60%;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 16px;
      }
    }

    & .splide__arrow {
      outline: none;

      & svg {
        width: 30px;
        height: 30px;
        fill: var(--tqc-dashboard-slider-arrow);
      }
    }

    & .splide__pagination {
      bottom: 24px;
    }

    & .splide__pagination__page {
      background: var(--tqc-dashboard-slider-pagination-dot);
      width: 8px;
      height: 8px;

      &.is-active {
        background: var(--tqc-dashboard-slider-pagination-dot-selected);
      }
    }
  }

  & .channels-text {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding: 0 5%;
    line-height: 1.5;

    & em {
      font-weight: 600;
      margin-left: 4px;
      margin-right: 4px;
    }
  }

  & .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;

    & .tqn-image-icon {
      width: 70px;
      height: 70px;
      cursor: pointer;
    }
  }
}
