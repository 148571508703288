.tqn-my-tickets {
  overflow: hidden;
  height: 100%;
  position: relative;

  & .tickets-list {
    height: 100%;
    overflow-x: auto;
    padding: 16px 16px 0;

    & > div {
      margin-bottom: 16px;
    }
  }
}

.my-tickets-tabs {
  margin-top: -43px;
}

.tqn-info-modal {
  width: 100%;
  height: 100%;
}
