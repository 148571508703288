.tqn-progress-bar {
  --tqc-bar: var(--tqc-green);

  position: relative;
  background: rgba(255, 255, 255, 0.08);
  height: 4px;
  border-radius: 2px;
  margin-bottom: 30px;

  & .tqn-progress-inner {
    background: var(--tqc-bar);
    height: 4px;
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 0;
    transition: width 200ms ease-in-out;
  }

  & .tqn-progress-circle {
    background: var(--tqc-bar);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0 0 10px var(--tqc-bar);
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    transition: left 200ms ease-in-out;
  }
}
