.single-event-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .to-label {
        font-size: 17px;
        margin: 0 12px;
        opacity: 0.2;
        flex: none;
        padding-top: 23px;
    }

    & .action-btn {
        width: 100%;
        margin-bottom: 16px;
    }


    & .error {
        color: var(--tqc-error);
        font-size: 14px;
        font-weight: 400;
        height: 20px;
        margin-bottom: 30px;
        text-align: center;
    }
}