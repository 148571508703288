.tqn-package-info {
  height: 100%;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .to-label {
    font-size: 17px;
    margin: 0 12px;
    opacity: 0.4;
    flex: none;
    padding-top: 23px;
  }

  & .tqn-continue-btn {
    margin-top: 15px;
  }

  & .info-block {
    display: flex;
    gap: 12px;
    padding: 18px 15px 20px ;
    background-color: var(--tqc-grey-2);
    border-radius: 12px;

    & .title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.4;
      padding-bottom: 6px;
    }

    & .text {
      line-height: 1.4;
    }

    & .icon {
      padding-top: 2px;
    }

    & .tqn-color-icon {
      flex: none;
      padding-top: 1px;

      & .question-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
}
