.tqn-dropdown-field {
  position: relative;

  & .label {
    position: absolute;
    transition: transform 150ms, opacity 150ms;
    transform-origin: top left;
    transform: scale(1) translateY(0);
    opacity: 0.4;
    white-space: nowrap;
    top: 24px;
  }

  & .tqn-dropdown {
    padding-top: 18px;
  }

  & .tqn-dropdown-selector {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid var(--tqc-horizontal-line-dark);
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
    outline: none;

    & span {
      text-align: left;
    }

    &:active,
    &:focus {
      border-bottom-color: var(--tqc-gold);
    }
  }

  &.error .tqn-dropdown-selector {
    border-bottom-color: var(--tqc-error);
  }

  & .tqn-error {
    color: var(--tqc-error);
    font-size: 11px;
    line-height: 20px;
    height: 20px;
  }

  &.has-value .label {
    transform: scale(0.75) translateY(-32px);
  }
}
