/* stylelint-disable no-descending-specificity */

.tqn-inventory-card {
  --tqc-ticket-bg: var(--tqc-grey-1);
  --tqc-ticket-date-bg: var(--tqc-grey-2);
  --tqc-ticket-date-text: var(--tqc-grey-4);
  --tqc-ticket-split: var(--tqc-grey-4);
  --tqc-new-sale-badge: var(--tqc-red);
  --tqc-playoff-desc: var(--tqc-grey-5);

  min-height: 84px;
  font-family: 'Poppins';
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 15px 0 #000;
  cursor: pointer;

  & .status-label {
    height: inherit;
    width: 18px;
    min-width: 18px;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    z-index: 200;

    &[data-status='LISTED'] {
      background-color: var(--tqc-green);
    }

    &[data-status='NOT_LISTED'] {
      background-color: var(--tqc-red);
    }

    &[data-status='SOLD'] {
      background: var(--tqc-gold);
    }

    &[data-status='SPLITS'] {
      background: var(--tqc-ticket-date-bg);
    }

    & .split-indicator {
      flex: none;
      position: relative;
      width: 100%;
      padding-top: 4px;
      padding-bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--tqc-ticket-split);

      &::before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        bottom: -12px;
        width: 0;
        height: 0;
        border-top: 6px solid var(--tqc-ticket-split);
        border-bottom: 6px solid transparent;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
      }

      & .tqn-color-icon {
        width: 11px;
        height: 11px;
      }
    }

    & .text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;

      &.pb-3 {
        padding-bottom: 3px;
      }
    }

    & .text {
      transform: rotate(-90deg);
      position: relative;
      text-transform: uppercase;
      color: black;
      flex-shrink: 0;
      display: flex;
      align-items: center;

      & > span {
        white-space: nowrap;
      }

      & img {
        transform-origin: center;
        transform: rotate(90deg);
        margin-left: 5px;
      }
    }
  }

  &.no-status .status-label {
    display: none;
  }

  & .date-panel.col {
    height: inherit;
    width: 74px;
    background-color: var(--tqc-ticket-date-bg);
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: var(--tqc-ticket-date-text);

    & .playoff-game-type {
      margin-bottom: 8px;

      & .playoff-final-icon {
        width: 39px;
        height: 27px;
      }

      & .playoff-world-series-icon {
        width: 39px;
        height: 35px;
      }

      & .playoff-game-icon {
        width: 49px;
        height: 21px;
      }
    }

    & .day-month {
      font-size: 16px;
      font-weight: 400;
      margin: 5px 0;
    }

    & .time {
      font-size: 10px;
      margin-top: 7px;
    }

    & .year {
      height: 12px;
    }

    &.tbd {
      font-size: 16px;
    }
  }

  &.no-status .date-panel {
    width: 92px;
  }

  & .details-panel {
    --padding-left: 14px;

    height: inherit;
    background-color: var(--tqc-ticket-bg);
    flex-grow: 2;
    overflow: hidden;

    & .matchup {
      width: 100%;
      padding-top: 8px;
      padding-left: var(--padding-left);
      padding-right: var(--padding-left);
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;

      & .event-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 22px;
        text-align: start;

        @supports (-webkit-line-clamp: 2) {
          white-space: initial;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      & .at-symbol {
        display: inline;
        position: relative;
        top: -2px;
        margin: 2px 6px 0;
        font-size: 14px;
      }

      & .home,
      & .away,
      & .at-symbol {
        display: inline;
      }

      & .modal {
        top: 0
      }

      & .highlighted {
        background: var(--tqc-gold);
      }
    }

    & .playoff-game-type {
      margin-top: 5px;
      margin-bottom: 5px;
      padding-left: var(--padding-left);

      & .playoff-nba-final-icon {
        width: 61px;
        height: 11px;
      }

      & .playoff-nhl-final-icon {
        width: 105px;
        height: 11px;
      }


      & .playoff-game-icon {
        width: 101px;
        height: 11px;
      }
    }

    & .seat-info.row {
      padding-left: var(--padding-left);
      font-size: 12px;
      margin-bottom: 8px;
      margin-top: 5px;
      justify-content: space-between;

      & > div {
        width: 33%;
      }

      & .label {
        margin-right: 8px;
        color: var(--tqc-ticket-date-text);
      }

      & .value {
        font-weight: 500;
      }

      & .tqn-color-icon {
        width: 12px;
        height: 12px;
      }
    }

    & .middle.row {
      margin-bottom: 9px;
      height: 30px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--tqc-horizontal-line-dark);

      & .new-sale-summary {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 500;
        margin-right: 8px;

        & .badge {
          color: var(--tqc-white);
          background: var(--tqc-new-sale-badge);
          font-size: 8px;
          font-weight: 600;
          padding: 3px 5px 1px;
          border-radius: 6px;
          margin-bottom: 1px;
        }

        & .tqn-color-icon {
          width: 8px;
          height: 10px;
          margin-bottom: 1px;
          margin-left: 1px;
        }
      }
    }

    & .price.row {
      font-size: 13px;
      padding: 8px 0 8px var(--padding-left);
      color: var(--tqc-gold);

      & .icon {
        margin-right: 5px;
        width: 12px;
        height: 12px;
      }

      & .label {
        margin-right: 6px;
        font-weight: 200;
        white-space: nowrap;
      }

      & .value {
        font-weight: 600;
      }
    }

    & .left-to-list {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      line-height: 1.3;
      padding: 8px 10px 8px 0;
      text-align: right;

      & .tqn-timer-suffix {
        padding-bottom: 16px;
      }
    }


    & .playoff-desc.row {
      font-size: 11px;
      padding: 0 0 0 var(--padding-left);
      line-height: 16px;
      color: var(--tqc-playoff-desc);
      text-align: left;
      margin-right: 24px;

      & .label {
        font-weight: 400;
      }

      & .ticket-label {
        min-height: 32px;
      }

      & .modal-label {
        margin-bottom: 12px;
      }

      & .status {
        white-space: nowrap;
        font-style: italic;
        margin-right: 6px;
      }
    }

    & .playoff.row {
      padding: 2px 8px;
      font-size: 11px;
      color: var(--tqc-text-dark);
      background-color: var(--tqc-grey-5);
      border-radius: 9px;
      margin-right: 7px;
      align-items: center;
      text-align: center;
      font-weight: 200;
      margin-left: 7px;

      & .label {
        font-weight: 600;
      }

      & .status {
        white-space: nowrap;
        font-style: italic;
        margin-right: 6px;
      }

      & .status-icon {
        width: 10px;
        height: 9px;
        min-width: 9px;
        margin-right: -4px;
      }
    }
  }

  &.no-price .details-panel {
    & .matchup {
      padding-top: 9px;
    }

    & .middle.row {
      display: none;
    }
  }

  &.group-header {
    height: 84px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 2px solid var(--tqc-horizontal-line-light);
    cursor: default;

    & .details-panel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 8px 0 12px 0;

      & .matchup {
        padding-top: 0;
      }
    }
  }

  &.group-item {
    margin-top: -21px;

    & .matchup.row {
      opacity: 0;
    }

    & .status-label,
    & .date-panel {
      padding-top: 15px;
    }

    & [data-status='LISTED'] + .dates-hidden::before {
      background: var(--tqc-green);
    }

    & [data-status='NOT_LISTED'] + .dates-hidden::before {
      background: var(--tqc-red);
    }

    & [data-status='SOLD'] + .dates-hidden::before {
      background: var(--tqc-gold);
    }
  }

  & [data-status='NOT_LISTED'] .text span {
    margin-top: 1px;
  }

  & .split-label {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 45%);
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(/images/splits-label-container.svg);
    color: var(--tqc-text-dark);
    width: 140px;
    height: 13px;

    & img {
      height: 9px;
      margin-right: 5px;
    }
  }

  & .badges {
    position: absolute;
    top: 7px;
    right: 7px;
    z-index: 200;
  }
}

.tqn-inventory-card-wrapper {
  position: relative;
}

.too-late-listing-mask {
  & .mask {
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.4;
  }
}

.too-late-listing-mask.group-header {
  & .mask {
    display: none!important;
  }
}

.text-align-started {
  text-align: start;
}
