/* Flex layout */
.row {
  display: flex;

  &.align-center {
    align-items: center;
  }

  &.align-baseline {
    align-items: baseline;
  }

  &.justify-space-around {
    justify-content: space-around;
  }

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-end {
    justify-content: end;
  }

  & .item {
    flex: 1;
  }
}

.col {
  display: flex;
  flex-direction: column;

  &.align-center {
    align-items: center;
  }

  &.flex-none {
    flex: none;
  }
}

/* Margins */
.mb-3 {
  margin-bottom: 3px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mx-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-56 {
  margin-bottom: 56px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

/* Padding */
.pb-10 {
  padding-bottom: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.p-24 {
  padding: 24px !important;
}

.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.px-36 {
  padding-left: 36px;
  padding-right: 36px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-10 {
  padding-left: 10px;
}

/* Width */

.w-60 {
  width: 60% !important;
}

.w-100pct {
  width: 100% !important;
}

.h-100pct {
  height: 100% !important;
}

.h-78 {
  height: 78px !important;
}

.br-10 {
  border-radius: 10px !important;
}

.flex-fill {
  flex: 1;
}

/* Opacity */
.opacity-2 {
  opacity: 0.2;
}

/* color */
.bc-dark {
  background-color: var(--tqc-app-bg);
}

/* Text alignment */
.text-center {
  text-align: center !important;
}

.no-break {
  white-space: nowrap;
}

.d-inline-block {
  display: inline-block;
}

.text-underline {
  text-decoration: underline;
}
