.tqn-alert {
  & .row {
    display: flex;
  }

  & .alert-icon {
    width: 26px;
    height: 26px;
    margin-right: 12px;

    &.size-sm {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  & .alert-text {
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;

    & .action-btn {
      margin-left: 4px;

      & button {
        font-size: 14px;
        font-weight: 600;
      }

      & .btn-arrow {
        width: 10px;
        height: 10px;
      }
    }
  }
}
