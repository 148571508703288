/* Common font styles */
.text-lg {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.text-lg-bold {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.text-lg-semibold {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
}

.text-md {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.text-md-light {
  font-size: 16px;
  font-weight: 200;
  line-height: 1.5;
}

.text-md-semibold {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

.text-md-bold {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
}

.text-smd {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.text-smd-light {
  font-size: 14px;
  font-weight: 200;
  line-height: 1.5;
}

.text-smd-semibold {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
}

.text-sm {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.text-sm-light {
  font-size: 12px;
  font-weight: 200;
  line-height: 20px;
}

.text-sm-bold {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
}

.text-xs {
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
}

.text-xs-light {
  font-size: 11px;
  font-weight: 200;
  line-height: 18px;
}

.text-xs-bold {
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
}

/* Common text colors */
.text-grey {
  color: var(--tqc-grey-4);
}

.text-gold {
  color: var(--tqc-gold);
}

.text-green {
  color: var(--tqc-green);
}

.text-red {
  color: var(--tqc-red);
}

.text-bright-red {
  color: var(--tqc-red-alert);
}

.text-underline {
  text-decoration: underline;
}

.text-italic {
  font-style: italic;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

/* Common backgrounds */

.tqn-bg-dark-glass {
  /* backdrop-filter: blur(5px); */
  background: rgba(0, 0, 0, 1);
}

.tqn-ul-gold li::before {
  content: '\2022';
  color: var(--tqc-gold);
  font-weight: bold;
  display: inline-block;
  width: 1em;
}
