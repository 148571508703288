.tqn-dashboard-header {
  --tqc-header-info: var(--tqc-gold);

  padding: 5px 0 24px;
  overflow: hidden;
  border-bottom: 1px solid var(--tqc-horizontal-line-dark);
  background-image: url('/images/bg-generic.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.nfl {
    background-image: url('/images/bg-football.jpg');
  }

  &.nba {
    background-image: url('/images/bg-basketball.jpg');
  }

  &.nhl {
    background-image: url('/images/bg-hockey.jpg');
  }

  &.mlb {
    background-image: url('/images/bg-baseball.jpg');
  }

  &.mls {
    background-image: url('/images/bg-soccer.jpg');
  }

  & .banner-row {
    background: rgba(13, 18, 18, 56%);
    z-index: 10;
    min-height: 46px;
  }

  & .title-row {
    display: grid;
    grid-template-columns: max-content 1fr 44px;
    align-items: center;
    position: relative;
    margin-bottom: 6px;
    background: rgba(13, 18, 18, 56%);
    padding: 10px 12px 12px 16px;
    z-index: 10;

    & .team-search-filter {
      position: relative;
      overflow: hidden;
    }

    & .tqn-season-selector {
      margin: 0 15px;

      span {
        margin-left: 24px;
      }
    }

    & .tqn-search-input {
      position: relative;
      z-index: 2;
      margin-left: 15px;
      padding: 0;
      margin-top: -30px;

      &.searching {
        width: calc(100% - 30px)!important;

        .search {
          border-radius: unset!important;
          border: none!important;
          background-color: unset!important;
        }
      }

      .button.search {
        width: 30px;
        height: 30px;
        border-radius: 25px;
        border: 1px solid var(--tqc-grey-3);
        background-color: var(--tqc-grey-2);

        .tqn-size-sm {
          width: 16px;
          height: 16px;
        }
      }

      .button.close {
        width: 30px;
        height: 30px;
      }
    }
  }

  & .title {
    width: 120px;
    transform: translateY(2px);
  }

  & .info-row {
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    margin-bottom: 28px;
  }

  & .info-block {
    flex: 1;
    text-align: center;
    line-height: 1.5;
    padding: 0 10px;
    color: var(--tqc-header-info);

    & .value {
      font-size: 28px;
      font-weight: 600;
    }

    & .label {
      font-size: 11px;
      margin-top: -4px;
    }
  }
}
