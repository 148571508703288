.tqn-search-input {
  position: relative;
  width: 44px;
  transition: width 300ms ease-in-out;
  padding: 7px 3px;
  margin-right: -3px;
  overflow: hidden;

  & input {
    width: 100%;
    height: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    opacity: 0;
    transition: padding 300ms ease-in-out, opacity 300ms ease-in-out;
    font-size: 14px;
    border: 1px solid transparent;
  }

  & .button {
    position: absolute;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    top: 0;

    &.search {
      transition: opacity 300ms ease-in-out;
      z-index: 1;
      left: 0;
      opacity: 1;
    }
  
    &.close {
      display: none;
      right: 0;
      z-index: 0;
    }

    & .tqn-color-icon {
      transition: width 300ms ease-in-out, height 300ms ease-in-out;
    }
  }

  &.searching {
    width: 55%;

    & .search {
      opacity: 0.5;
      cursor: default;

      & img {
        width: 16px;
        height: 16px;
      }
    }

    & input {
      opacity: 1;
      padding-left: 32px;
      padding-right: 28px;
      border-radius: 15px;
      border-color: var(--tqc-horizontal-line-light);
    }
  }
}
