.tqn-new-fee-indicator {
  position: absolute;
  color: var(--tqc-grey);
  padding: 8px 20px;
  text-align: center;
  z-index: 999;
  right: 5px;
  top: -60px;
  animation: feebobbing .6s ease-in-out 0s infinite alternate;
  background-image: url(/images/Bubble-Shadow.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 72px;
  cursor: pointer;

  & .text {
    font-size: 18px;
    font-weight: 600;
  }

  & .tqn-button button {
    font-weight: 400;
    color: var(--tqc-grey);
  }
}

.tqn-fee-indicator-anim {
  &.tqn-anim-end {
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  }

  &.tqn-enter {
    &.tqn-anim-start {
      opacity: 0;
    }

    &.tqn-anim-end {
      opacity: 1;
    }
  }

  &.tqn-leave {
    &.tqn-anim-start {
      opacity: 1;
    }

    &.tqn-anim-end {
      opacity: 0;
    }
  }
}

@keyframes feebobbing {
  from { transform: translateY(0); }
  to { transform: translateY(-5px); }
}
