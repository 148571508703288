.tqn-action-sheet {
  --tqc-action-sheet-bg: var(--tqc-modal-bg);
  --tqc-item-green: var(--tqc-green);
  --tqc-item-red: var(--tqc-red);
  --tqc-item-default: var(--tqc-grey-4);

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--tqc-action-sheet-bg);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  text-align: center;
  transform: translateY(100%);
  transition: transform 200ms ease-in-out;
  z-index: 999;
  padding-bottom: 10px;

  & .tqn-actionsheet-title {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }

  & .tqn-actionsheet-subtitle {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-top: 3px;
  }

  & .tqn-actionsheet-header {
    padding: 16px;
  }

  & .tqn-actionsheet-item {
    padding: 15px;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    border-top: 1px solid rgba(84, 84, 88, 0.65);
    cursor: pointer;
    color: var(--tqc-item-default);

    &.red {
      color: var(--tqc-item-red);
    }

    &.green {
      color: var(--tqc-item-green);
    }
  }

  &.open {
    transform: translateY(0);
  }

  &.closed {
    height: 0;
    padding-bottom: 0;
    overflow: hidden;
  }
}

.tqn-actionsheet-mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(16, 17, 17);
  opacity: 0;
  z-index: 998;
  transition: opacity 300ms ease-in-out;

  &.open {
   opacity: 0.7;
  }

  &.closed {
   height: 0;
   padding-bottom: 0;
   overflow: hidden;
  }
}