.tqn-something-went-wrong-modal {
  & .title {
    font-size: 24px;
  }

  & .tqn-button {
    width: 190px;
    margin: 25px auto 0;
  }
}
