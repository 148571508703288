.tqn-pricing-factors-modal {
  --tqc-pf-icon-pro: var(--tqc-green);
  --tqc-pf-icon-con: var(--tqc-red);
  --tqc-pf-icon-default: var(--tqc-grey-3);
  --tqc-modal-title: var(--tqc-gold);

  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  & .content {
    width: 80%;
    border-radius: 10px;
    background-color: var(--tqc-modal-bg);
    padding: 25px;
    position: relative;

    & .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;

      & .tqn-color-icon {
        opacity: 0.4;
      }
    }

    & .title {
      font-size: 26px;
      font-weight: bold;
      color: var(--tqc-modal-title);
      margin-bottom: 20px;
      text-align: center;
    }

    & .description {
      font-size: 14px;
      line-height: 22px;
      text-align: center;
    }

    & .row {
      display: flex;
      align-items: center;
      gap: 20px;
      height: 45px;
      border-bottom: 1px solid #78787880;
      padding-left: 32px;

      &:last-child {
        border-bottom: none;
      }

      & .icon-pro,
      & .icon-con {
        display: block;
        width: 24px;
        text-align: center;
        font-size: 26px;
      }

      & .icon-pro {
        color: var(--tqc-pf-icon-pro);
      }

      & .icon-con {
        color: var(--tqc-pf-icon-con);
      }

      & .icon-na {
        display: block;
        font-size: 14px;
        text-align: center;
        width: 24px;
        color: var(--tqc-pf-icon-default);
      }

      & .category-name {
        font-size: 16px;
      }
    }
  }
}
