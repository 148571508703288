.tqn-team-creds-modal {
  background: var(--tqc-app-bg);
  display: flex;
  flex-direction: column;

  & .tqn-account-nav-header {
    flex: none;
  }

  & .tqn-team-creds-details {
    flex: 1;
    overflow-y: auto;
  }
}
