.tqn-color-icon {
  --tqc-color-icon: var(--tqc-white);

  width: 24px;
  height: 24px;
  object-fit: contain;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  background-color: var(--tqc-color-icon);

  &.tqn-size-xxs {
    width: 14px;
    height: 14px;
  }

  &.tqn-size-xs {
    width: 16px;
    height: 16px;
  }

  &.tqn-size-sm {
    width: 20px;
    height: 20px;
  }

  &.tqn-size-lg {
    width: 32px;
    height: 32px;
  }

  &.tqn-size-xl {
    width: 48px;
    height: 48px;
  }
}
