.tqn-are-you-season-ticket-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: -110px;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 0 20px;
    }


    .title {
        font-weight: bold;
        font-size: 26px;
        line-height: 1.4;
        text-align: center;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        text-align: center;
    }

    .btn-group {
        margin-top: 40px;
        width: 70%;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}