body {
  /* Base colors */
  --tqc-grey: #101111;
  --tqc-grey-1: #242626;
  --tqc-grey-2: #363838;
  --tqc-grey-3: #707273;
  --tqc-grey-4: #929393;
  --tqc-grey-5: #cdcbcb;
  --tqc-grey-6: #e3e3e3;
  --tqc-gold: #f9bb5c;
  --tqc-gold-1: #9a5123;
  --tqc-gold-2: #b45d29;
  --tqc-gold-3: #ce8f39;
  --tqc-gold-4: #f9bb5c;
  --tqc-gold-5: #fcd38e;
  --tqc-gold-6: #feebcd;
  --tqc-green: #009a9e;
  --tqc-green-1: #0b373a;
  --tqc-green-2: #0f444b;
  --tqc-green-3: #00767a;
  --tqc-green-4: #009a9e;
  --tqc-green-5: #53c4c6;
  --tqc-green-6: #ade5e6;
  --tqc-red: #c04a4e;
  --tqc-red-1: #41191a;
  --tqc-red-2: #5f2729;
  --tqc-red-3: #823236;
  --tqc-red-4: #c04a4e;
  --tqc-red-5: #e58889;
  --tqc-red-6: #f4c6be;
  --tqc-red-alert: #e8464b;
  --tqc-white: #fff;

  /* Derived colors */
  --tqc-app-bg: var(--tqc-grey);
  --tqc-modal-bg: var(--tqc-grey-1);
  --tqc-popup-box: var(--tqc-grey-2);
  --tqc-horizontal-line-dark: var(--tqc-grey-2);
  --tqc-horizontal-line-light: var(--tqc-grey-3);
  --tqc-info-text: var(--tqc-grey-4);
  --tqc-menu-arrow: var(--tqc-grey-4);
  --tqc-text-light: var(--tqc-white);
  --tqc-text-dark: var(--tqc-grey);
  --tqc-list-action: var(--tqc-green);
  --tqc-delist-action: var(--tqc-red);
  --tqc-success: var(--tqc-green);
  --tqc-error: var(--tqc-red);
  --tqc-alert: var(--tqc-red);
  --tqc-otp-caption: var(--tqc-grey-4);
  --tqc-otp-label: var(--tqc-grey-6);
  --tqc-otp-resend: var(--tqc-gold-3);
  --tqc-otp-spinner: var(--tqc-green);
  --tqc-otp-spam-check: var(--tqc-gold);

  /* font */
  --tqf-poppins: 'Poppins', sans-serif;

  /* size */
  --max-width: 460px;
}
