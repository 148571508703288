.tqn-list-tabs {
  --tqc-tab-text: var(--tqc-white);
  --tqc-tab-selected: var(--tqc-gold);

  display: flex;
  justify-content: stretch;
  text-align: center;
  font-size: 18px;
  border-bottom: 1px solid var(--tqc-horizontal-line-dark);
  position: relative;
  overflow: hidden;

  & .tab {
    flex: 1;
    color: var(--tqc-tab-text);
    padding-top: 10px;
    padding-bottom: 14px;
    cursor: pointer;

    &.selected {
      font-weight: 600;
      color: var(--tqc-tab-selected);
    }
  }

  & .selected-bar {
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 25%;
    height: 8px;
    border-radius: 8px;
    background-color: var(--tqc-tab-selected);
    transition: left 300ms ease-in-out;
  }
}
