.tqn-single-event-confirmation-modal {
    overflow-y: hidden;
    overflow-x: hidden;

    & .header {
        margin-top: 20px;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
    }

    & .content {
        margin-top: 6px;
        font-size: 14px;
        line-height: 22px;
    }

    & .highlights {
        font-weight: 600;
    }
}