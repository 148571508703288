.tqn-dropdown-menu {
  background: var(--tqc-modal-bg);
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
  overflow: hidden;
  position: fixed;
  z-index: 999;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  outline: none;

  & .list {
    height: 100%;
    overflow: auto;
    flex: 1;
  }

  & .tqn-menu-item {
    cursor: pointer;
    padding: 6px 28px 6px 12px;
    text-align: center;
    line-height: 22px;
    outline: none;

    &:hover,
    &:active,
    &:focus,
    &.selected {
      background: rgba(255, 255, 255, 0.05);
    }
  }
}
