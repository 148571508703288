@keyframes pulse-orange {
    0% {
        box-shadow: 0 0 0 0 rgba(252, 176, 24, .7);
    }

    70% {
        box-shadow: 0 0 0 10px transparent;
    }

    100% {
        box-shadow: 0 0 0 0 transparent;
    }
}

.tqn-welcome-onboarding {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 0 25px;
    }

    .title {
        text-align: center;
        font-weight: bold;
        font-size: 32px;
        color: var(--tqc-gold);
        line-height: 1.4;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-right: 10px;

        .line {
            display: flex;
            gap: 20px;

            .label {
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                color: var(--almost-white);
            }

            .sub-label {
                font-size: 18px;
                font-weight: 400;
                line-height: 1.4;
            }
        }

        .row {
            display: flex;
            gap: 10px;
        }
    }

    .sub-content {
        margin-top: 15px;
        margin-left: 12px;
        margin-right: 0;
        gap: 20px;
    }

    .divider {
        width: 50%;
        border-radius: 4px;
    }

    .get-started-btn {
        width: 65%;
        margin-top: 40px;

        button {
            animation: pulse-orange 1.5s infinite;
        }
    }

    .align-center {
        text-align: center;
    }

    .icon-placeholder {
        width: 20px;
        height: 20px;
    }
}