.tqn-code-field {
  --tqc-code-input-error: var(--tqc-grey-4);
  --tqc-code-input: var(--tqc-green);

  display: flex;
  flex-direction: column;
  align-items: center;

  & .code-input {
    display: flex;
    gap: 8px;
    justify-content: center;

    & input {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      appearance: none;
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 20px;
      color: var(--tqc-code-input);
      width: 1em;
      text-align: center;
    }

    & .underline {
      background-color: var(--tqc-code-input);
      height: 2px;
      width: 100%;
      margin-top: 2px;
    }
  }

  & .error-message {
    color: var(--tqc-code-input-error);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-top: 12px;
    opacity: 0;
  }
}
