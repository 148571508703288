.tqn-masquerade {
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .actions {
    display: flex;
    gap: 10px;
    padding-bottom: 16px;

    & .cancel-btn {
      flex: 0.7;
    }

    & .submit-btn {
      flex: 1;
    }
  }

  & .error {
    color: var(--tqc-error);
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    margin-bottom: 30px;
    text-align: center;
  }
}
