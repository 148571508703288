.tqn-tickets-confirmation {
  margin-bottom: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .tickets-container {
    width: 100%;

    & .tqn-ticket:not(:first-child) {
      margin-top: 20px;
    }
  }

  & .actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    bottom: 0;
    background: var(--tqc-app-bg);
    box-shadow: 0 -10px 20px var(--tqc-app-bg);
    padding-top: 1px;
  }

  & .tqn-add-btn {
    margin-top: 0;
    margin-bottom: 15px;
  }

  & .tqn-continue-btn {
    margin-top: 0;
  }

  & .tqn-submit-btn {
    margin-top: 0;
  }

  & .error {
    color: var(--tqc-error);
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    margin-top: 24px;
    text-align: center;
  }
}
