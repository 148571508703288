.tqn-accounts-menu-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 14px 6px 14px 12px;
  border-bottom: 1px solid var(--tqc-horizontal-line-dark);
  cursor: pointer;

  & .menu-icon {
    margin-right: 20px;
    flex: none;
  }

  & .menu-alert-icon {
    margin-right: 5px;
    width: 26px;
    height: 26px;
  }

  & .menu-label {
    flex: 1;
    line-height: 26px;
  }

  & .menu-arrow {
    flex: none;
  }

  &:last-child {
    border-bottom: none;
  }

  &.alert {
    border-bottom: none;
    background-color: var(--tqc-alert);
    border-radius: 8px;
  }

   &.disabled {
    cursor: not-allowed;
    color: var(--tqc-grey-3);

    & .menu-icon {
      background-color: var(--tqc-grey-3)!important;
    }
   }
}
