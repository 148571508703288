.tqn-support {
  --tqc-highlighted-value: var(--tqc-green);

  padding: 10px 24px;

  & .readonly {
    cursor: default;
  }

  & .tqn-accounts-menu-item.email .value {
    color: var(--tqc-highlighted-value);
    text-decoration: underline;
  }

  & .tqn-accounts-menu-item.phone .value {
    color: #fff;
    text-decoration: none;
  }
}