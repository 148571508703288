.tqn-bank-account {
  --tqc-bank-account-disclaimer-label: var(--tqc-white);
  --tqc-bank-account-disclaimer-text: var(--tqc-grey-3);

  padding: 10px 24px 24px;

  & .disclaimer {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 20px;
    color: var(--tqc-bank-account-disclaimer-text);

    & .label {
      font-weight: 600;
      color: var(--tqc-bank-account-disclaimer-label);
    }
  }

  & .actions {
    display: flex;
    gap: 10px;
    padding-bottom: 16px;

    & .tqn-button {
      flex: 1;
    }
  }

  & .error {
    color: var(--tqc-error);
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    margin-bottom: 30px;
    text-align: center;
  }

  & .wiretransfer {
    font-size: 12px;
  }

  & .bank-name {
    position: relative;

    & .success {
      position: absolute;
      left: 90px;
      top: -48px;
      font-size: 12px;
    }

    & .lds-spinner {
      position: absolute;
      right: -84px;
      top: -42px;
    }
  }
}
