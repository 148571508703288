.tqn-slider {
  --tqc-slider-progress: var(--tqc-green);

  height: 4px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.08);
  transition: opacity 0.2s;
  opacity: 1;
  position: relative;

  & .tqn-progress-inner {
    background: var(--tqc-slider-progress);
    height: 4px;
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 0;
  }

  & input {
    appearance: none;
    width: 100%;
    outline: none;
    background: transparent;
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    top: 50%;

    &::-webkit-slider-thumb {
      appearance: none;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background: var(--tqc-slider-progress);
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 26px;
      height: 26px;
      background: var(--tqc-slider-progress);
      cursor: pointer;
    }
  }
}
