.tqn-notification-center {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;

    .tqn-notification-message {
        display: flex;
        gap: 10px;
        padding: 15px 10px;
        border-radius: 10px;
        background-color: var(--tqc-grey-2);

        &.read {
            background-color: var(--tqc-grey-1);
        }

        & .status {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background-color: var(--tqc-gold);

            &.read {
                background-color: unset!important;
                border: 1px solid var(--tqc-grey-4);
            }
        }

        & .body {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-right: 5px;

            & .date {
                font-size: 12px;
                color: var(--tqc-grey-4);
            }

            & .content {
                font-size: 14px;
                font-weight: 600;
                line-height: 22px;

                &.read {
                    font-weight: 400;
                }
            }
        }
    }
}

.notification-btn {
    cursor: pointer;
    position: relative;

    & .counter {
        position: absolute;
        z-index: 1;
        margin-left: 20px;
        margin-top: -5px;
        width: 22px;
        height: 22px;
        border-radius: 10px;
        background-color: var(--tqc-green);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;

        &.hidden {
            display: none;
        }
    }
}

.tqn-notification-center-modal {
    .tqn-screen-content {
        padding-top: 64px;
        padding-bottom: 14px;
    }
}

.ios_mobile_app {
    .tqn-notification-center-modal {
        .tqn-screen-content {
            padding-top: 30%;
            padding-bottom: 14px;
        }
    }
}