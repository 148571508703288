@keyframes BannerfadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-120px);
  }

  60% {
    opacity: 0.5;
    transform: translateY(6px);
  }

  90% {
    transform: translateY(-2px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes BannerfadeInTop2 {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  90% {
    transform: translateY(-2px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes BellShake {
  0% {
    transform-origin: 50% 20%;
    transform: rotate(0deg);
  }

  35% {
    transform-origin: 50% 20%;
    transform: rotate(50deg);
  }

  55% {
    transform-origin: 50% 20%;
    transform: rotate(-50deg);
  }

  75% {
    transform-origin: 50% 20%;
    transform: rotate(25deg);
  }

  90% {
    transform-origin: 50% 20%;
    transform: rotate(-10deg);
  }

  100% {
    transform-origin: 50% 20%;
    transform: rotate(0deg);
  }
}

.banner-block {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  background-color: white;
  border-radius: 10px;
  color: var(--tqc-text-dark) !important;
  cursor: pointer;

  .banner-info {
    font-size: 16px;
    line-height: 1.4;
    flex: 1;

    .text-center {
      text-align: center;
    }
  }

  &.align-start {
    align-items: start;
  }

  .banner-info button {
    height: 22px;
  }

  .banner-info span {
    font-size: 14px;
    line-height: 22px;
  }

  .close-btn {
    height: 100%;
  }

  .tqn-color-icon.bell-shake {
    animation: BellShake 0.75s;
  }

  .banner-icon {
    width: 26px;
    height: 26px;
    min-width: 26px;
    min-height: 26px;
  }

  &.border-sm {
    border-radius: 10px;
  }
}

.banner-block.fade-in-top {
  animation: BannerfadeInTop 0.75s;
}

.banner-block.fade-in-top2 {
  animation: BannerfadeInTop2 0.75s;
}

.banner-block.mx-15 {
    margin-left: 15px;
    margin-right: 15px;
}

.banner-block.waiting-dashboard {
  justify-content: space-between;
  align-items: unset;
  padding-right: 10px;
}

.tqn-app-banners {
  display: flex;
  flex-direction: column;
  gap: 10px;
}