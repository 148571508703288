.tqn-sold-item-details-modal {
  --tqc-details-modal-bg: var(--tqc-grey);
  --tqc-details-modal-bg: var(--tqc-grey);
  --tqc-details-modal-status-label: var(--tqc-grey-2);
  --tqc-pf-card-bg-1: var(--tqc-grey-1);
  --tqc-pf-card-bg-2: var(--tqc-grey-2);
  --tqc-pf-card-selected: var(--tqc-white);
  --tqc-details-btn: var(--tqc-grey-4);
  --tqc-details-modal-tip: var(--tqc-gold);
  --tqc-details-modal-date: var(--tqc-gold);
  --tqc-details-modal-slider-arrow: var(--tqc-grey-4);
  --tqc-details-modal-slider-page-dot: var(--tqc-grey-4);
  --tqc-details-modal-slider-page-dot-active: var(--tqc-white);

  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--tqc-details-modal-bg);
  transition: filter 300ms;

  & .content {
    padding: 20px 25px;
    flex: 1;
    overflow: auto;

    & .tqn-inventory-card-wrapper {
      margin-top: -10px;
      margin-bottom: 30px;

      & .details-panel {
        display: flex;
        flex-direction: column;
        justify-content: center;

        & .home {
          font-size: 24px;
        }

        & .at-symbol {
          font-size: 20px;
          top: -2px;
        }

        & .away {
          font-size: 24px;
        }

        & .playoff-game-type {
          margin-top: 0;
        }
      }

      & .status-label {
        background: var(--tqc-details-modal-status-label);
        display: none;
      }

      & .date-panel.col {
        width: 92px;
      }
    }

    & .title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 12px;
      text-align: center;
    }

    & .pf-title {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;

      & .plus-minus {
        display: flex;
        align-items: center;

        & > span {
          display: inline-block;
          font-size: 28px;
          line-height: 18px;
          margin-right: 4px;
        }
      }
    }

    & .price-factors {
      display: grid;
      gap: 10px;
      margin-bottom: 12px;
      grid-template-columns: repeat(2, 1fr);
    }

    & .pf-card {
      border-radius: 12px;
      background: var(--tqc-pf-card-bg-1);
      background: linear-gradient(
        180deg,
        var(--tqc-pf-card-bg-2) 0%,
        var(--tqc-pf-card-bg-2) 100%
      );
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      min-height: 90px;
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;
      padding: 15px 20px;

      &.selected {
        border: 2px solid var(--tqc-pf-card-selected);
      }
    }

    & .pf-details {
      cursor: default;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 12px;

      &.pf-card {
        height: auto;
      }

      & .details-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        line-height: 22px;
      }
    }

    & .sales-receipt {
      position: relative;

      & .tqn-new-fee-indicator {
        right: -15px;
        top: -64px;
      }
    }

    & .details-btn {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: var(--tqc-details-btn);
      cursor: pointer;
      gap: 4px;
    }

    & .line {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;

      & .label {
        width: 70%;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      & .value {
        display: flex;
        align-items: center;
        justify-content: end;
        flex-grow: 1;
        gap: 6px;
        font-weight: 600;
      }

      &.tip {
        color: var(--tqc-details-modal-tip);
        margin-top: 6px;
        font-weight: 200;
      }
    }

    & .payment-date {
      color: var(--tqc-details-modal-date);
      margin-top: 16px;

      & .missing-payment-info button {
        display: flex;
        align-items: center;
        gap: 6px;

        & .text-smd {
          text-decoration: underline;
        }
      }
    }

    & .tqn-pf-slider {
      width: 100%;
      padding-bottom: 24px;
      margin-top: 5px;

      & .splide__track {
        height: 100%;

        & .splide__list {
          position: relative;
          z-index: 0;
        }
      }

      & .splide__slide {
        display: flex;
        align-items: center;
        justify-content: center;

        & span {
          max-width: 60%;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.5;
          margin-bottom: 16px;
        }
      }

      & .splide__arrow {
        outline: none;

        & svg {
          width: 30px;
          height: 30px;
          fill: var(--tqc-details-modal-slider-arrow);
        }
      }

      & .splide__pagination {
        bottom: 0;
      }

      & .splide__pagination__page {
        background: var(--tqc-details-modal-slider-page-dot);
        width: 6px;
        height: 6px;

        &.is-active {
          background: var(--tqc-details-modal-slider-page-dot-active);
        }
      }
    }
  }

  &.blurred {
    filter: blur(10px);
  }

  & .notification {
    padding: 0 25px 14px 25px;

    & .info {
      display: flex;
      align-items: center;

      & .icon {
        min-width: 26px;
        margin-right: 12px;
      }

      & .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        & span {
          font-weight: 600;
          font-style: italic;
          text-decoration: underline;
        }

        & em {
          font-weight: 600;
          font-style: italic;
        }
      }
    }
  }

  & .referral-program-section {
    margin-top: 30px;
    padding: 0 12%;
  }

  & .no-wrap {
    white-space: nowrap;
  }
}
