.tqn-bottom-nav-tabs {
  --tqc-bottom-nav-selected: var(--tqc-gold);
  --tqc-bottom-nav-icon: var(--tqc-white);

  height: 76px;
  background-color: #161616f0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--tqc-horizontal-line-dark);
  position: relative;

  & .tab {
    max-width: 130px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    & img {
      height: 23px;
    }

    & .label {
      font-size: 11px;
      margin-top: 8px;
    }

    & .tqn-color-icon {
      background-color: var(--tqc-bottom-nav-icon);
      width: 36px;
      height: 36px;
    }

    & .badge {
      position: absolute;
      top: 12px;
      left: calc(50% + 5px);
      border-radius: 50%;

      &::before {
        content: ' ';
        display: block;
        position: absolute;
        top: 10%;
        right: 10%;
        width: 80%;
        height: 80%;
        border-radius: 50%;
        background-color: var(--tqc-bottom-nav-icon);
      }
    }

    &.selected {
      color: var(--tqc-bottom-nav-selected);

      & .tqn-color-icon {
        background-color: var(--tqc-bottom-nav-selected);
      }
    }
  }

  & .selected-bar {
    display: block;
    position: absolute;
    top: -4px;
    left: 0;
    width: 100%;
    height: 8px;
    border-radius: 8px;
    background-color: var(--tqc-bottom-nav-selected);
    transition: left 300ms ease-in-out;
  }
}
