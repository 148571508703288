.tqn-toggle-button {
  border: 1px solid var(--tqc-horizontal-line-light);
  border-radius: 18px;
  height: 36px;
  box-sizing: border-box;
  display: flex;

  & .button {
    cursor: pointer;
    border-right: 1px solid var(--tqc-horizontal-line-light);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &.selected {
      background-color: var(--tqc-gold);
      color: var(--tqc-text-dark);
    }

    &:first-child {
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
    }

    &:last-child {
      border-right: none;
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
    }
  }
}
