.tqn-login-otp {
  padding: 24px 24px 24px 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .otp-spinner {
    transform: scale(0.4);
    height: 40px;
    margin-bottom: 20px;
    opacity: 0;
    transition: opacity 300ms;

    &.show {
      opacity: 1;
    }

    & div::after {
      background: var(--tqc-otp-spinner);
    }
  }

  & .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    margin: 0 auto;
  }

  & .label {
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--tqc-otp-label);
    opacity: 0.9;
  }

  & .resend.resend {
    color: var(--tqc-otp-resend);
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-decoration: none;
    padding: 0 8px;
  }

  & .caption {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0 auto;
    width: 240px;
    text-align: center;
    color: var(--tqc-otp-caption);
  }

  & .message {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: var(--tqc-app-bg);
    opacity: 0;

    &.success {
      color: var(--tqc-success);
    }

    &.error {
      color: var(--tqc-error);
    }
  }

  & .spam-check {
    color: var(--tqc-otp-spam-check);
    margin-top: 2px;
    margin-bottom: 14px;
    text-align: center;
  }
}
