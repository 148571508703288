@import '@splidejs/splide/dist/css/splide.min.css';
@import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
@import '../util/meyerweb_resets.css';
@import '../util/tiq_resets.css';
@import './common/styles/animations.css';
@import './common/styles/common.css';
@import './common/styles/fonts.css';
@import './common/styles/text.css';
@import './common/styles/utils.css';
@import './common/styles/variables.css';

@import './common/components/ActionSheet/ActionSheet.css';
@import './common/components/Alert/Alert.css';
@import './common/components/AlertNavBottom/AlertNavBottom.css';
@import './common/components/BottomNavTabs/BottomNavTabs.css';
@import './common/components/Button/Button.css';
@import './common/components/Checkbox/Checkbox.css';
@import './common/components/CodeInput/CodeInput.css';
@import './common/components/ColorIcon/ColorIcon.css';
@import './common/components/CrossedText/CrossedText.css';
@import './common/components/Divider/Divider.css';
@import './common/components/DomainButtons/DomainButtons.css';
@import './common/components/Dropdown/Dropdown.css';
@import './common/components/Dropdown/DropdownSelector.css';
@import './common/components/Dropdown/DropdownMenu.css';
@import './common/components/DropdownField/DropdownField.css';
@import './common/components/Header/Header.css';
@import './common/components/ImageButton/ImageButton.css';
@import './common/components/ImageIcon/ImageIcon.css';
@import './common/components/InfoModal/InfoModal.css';
@import './common/components/InfoModal/contents/contents.css';
@import './common/components/Input/Input.css';
@import './common/components/InventoryCard/InventoryCard.css';
@import './common/components/Layout/Layout.css';
@import './common/components/Modal/Modal.css';
@import './common/components/OtpModal/OtpModal.css';
@import './common/components/PasswordInput/PasswordInput.css';
@import './common/components/ProgressBar/ProgressBar.css';
@import './common/components/RadioGroup/RadioGroup.css';
@import './common/components/Registration/TicketTypes/TicketTypes.css';
@import './common/components/Registration/NumOfGames/NumOfGames.css';
@import './common/components/Registration/PackageInfo/PackageInfo.css';
@import './common/components/Registration/TicketsConfirmation/TicketsConfirmation.css';
@import './common/components/Registration/HowWeCanHelp/HowWeCanHelp.css';
@import './common/components/SearchInput/SearchInput.css';
@import './common/components/Slider/Slider.css';
@import './common/components/Spinner/Spinner.css';
@import './common/components/Ticket/Ticket.css';
@import './common/components/Banner/Banner.css';
@import './common/components/Buttons/NextButton/NextButton.css';
@import './common/components/Buttons/SubmitButton/SubmitButton.css';
@import './common/components/Buttons/AddSeatsButton/AddSeatsButton.css';
@import './common/components/ReferralProgram/ReferralProgram.css';
@import './common/components/ToggleButton/ToggleButton.css';
@import './common/components/NotificationCenter/NotificationCenter.css';
@import './common/components/Registration/FreeDashboard/FreeDashboard.css';
@import './common/components/Registration/AreYouSeasonTicketHolder/AreYouSeasonTicketHolder.css';
@import './common/components/SplitFeeDetailsButton/SplitFeeDetailsButton.css';
@import './unauthenticated/landing/Landing.css';
@import './unauthenticated/login/LoginOtp/LoginOtp.css';
@import './unauthenticated/registration/PersonalInfo/PersonalInfo.css';
@import './unauthenticated/registration/RegistrationOtp/RegistrationOtp.css';
@import './waiting/Waiting.css';
@import './main/Dashboard/Dashboard.css';
@import './main/Dashboard/components/Header/Header.css';
@import './main/MyTickets/MyTickets.css';
@import './main/MyTickets/components/AnimationWrapper/AnimationWrapper.css';
@import './main/MyTickets/components/InventoryModal/InventoryModal.css';
@import './main/MyTickets/components/InventoryModalActionsheet/InventoryModalActionsheet.css';
@import './main/MyTickets/components/Actionsheet/Actionsheet.css';
@import './main/MyTickets/components/PricingFactorsModal/PricingFactorsModal.css';
@import './main/MyTickets/components/Seat/Seat.css';
@import './main/MyTickets/components/SoldItemDetailsModal/SoldItemDetailsModal.css';
@import './main/MyTickets/components/TeamCredsModal/TeamCredsModal.css';
@import './main/MyTickets/components/ListTabs/ListTabs.css';
@import './main/MyTickets/components/LoadingAnimation/LoadingAnimation.css';
@import './main/MyTickets/components/LastMinuteListingTimer/LastMinuteListingTimer.css';
@import './main/MyTickets/components/NewFeeIndicator/NewFeeIndicator.css';
@import './main/Account/Account.css';
@import './main/Account/PersonalInfo/PersonalInfo.css';
@import './main/Account/components/Header/Header.css';
@import './main/Account/components/MenuItem/MenuItem.css';
@import './main/Account/components/NavHeader/NavHeader.css';
@import './main/Account/Masquerade/Masquerade.css';
@import './main/Account/Payments/PaymentHistory/PaymentHistory.css';
@import './main/Account/Payments/PaymentHistory/components/PaymentDetailsModal/PaymentDetailsModal.css';
@import './main/Account/Payments/BankAccount/BankAccount.css';
@import './main/Account/Payments/TaxInformation/TaxInformation.css';
@import './main/Account/TeamCredentials/TeamCredsList/TeamCredsList.css';
@import './main/Account/TeamCredentials/TeamCredsDetails/TeamCredsDetails.css';
@import './main/Account/TeamCredentials/components/TeamCredsForm/TeamCredsForm.css';
@import './main/Account/DeleteAccount/DeleteAccount.css';
@import './main/Account/Support/Support.css';
@import './WelcomeOnboarding/WelcomeOnboarding.css';
@import './common/utils/errors/errors.css';
@import './main/Account/TicketManagement/SingleEvent/SingleEventForm/SingleEventForm.css';
@import './main/Account/TicketManagement/SingleEvent/SingleEventConfirmationModal/SingleEventConfirmationModal.css';
@import './main/Account/TicketManagement/components/MyTicketMenuItemGroup/MyTicketMenuItemGroup.css';
@import './main/Account/TicketManagement/MySeasonTickets/MySeasonTickets.css';
@import './main/Account/TicketManagement/AddNewTickets/AddNewTickets.css';
@import './main/Account/TicketManagement/SeasonTicketReactivation/SeasonTicketReactivation.css';
@import './main/Account/TicketManagement/components/SeatSubmission/SeatSubmissionModal.css';
@import './main/Account/TeamCredentials/components/VerificationDetails/VerificationDetails.css';

@import './FirstLoading/FirstLoading.css';

html {
  background-color: #ccc;
  height: 100%;
}

body {
  font-size: 16px;
  font-family: var(--tqf-poppins);
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  /* -webkit-overflow-scrolling: touch; */
  color: var(--tqc-white);
  display: flex;
  flex-direction: column;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}

#conversations-wrap {
  transition: opacity 300ms;
  margin-bottom: 72px;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  position: relative;
  background-color: var(--bg);
}

.desktop #app {
  overflow: hidden;
  height: 100%;
  min-height: 600px;
  flex-grow: 1;
  box-shadow: 0 15px 20px 5px #999;
  border-radius: 6px;
  margin-top: 30px;
  margin-bottom: 30px;

  & ::-webkit-scrollbar {
    background: #2d3232;
    width: 15px;
  }

  & ::-webkit-scrollbar-thumb {
    background: #545858;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
    -webkit-border-radius: 1ex;
  }

  & ::-webkit-scrollbar-corner {
    background: #0d1212;
  }
}

.unauthenticated-app {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

body.desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background-color: transparent;
}

div[data-lastpass-icon-root] {
  display: none !important;
}

.app-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);

  & .lds-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
  }
}

@keyframes move {
  0% {
    transform: translate(-100%, 0);
  }

  100% {
    transform: translate(calc(7 / 2 * 100%), 0);
  }
}

.tiq-network-progress {
  --progress-bg: var(--tqc-grey-2);
  --progress-bar: var(--tqc-gold);

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  overflow: hidden;
  background-color: var(--progress-bg);
  transition: opacity 0.2s ease-in;
  opacity: 0;
  z-index: 9998;

  &.show {
    opacity: 1;

    & .bar {
      position: absolute;
      width: calc(100% * 2 / 7);
      height: 100%;
      display: flex;
      animation: move 2s linear infinite;
      background-color: var(--progress-bar);
    }
  }
}

* {
  -webkit-font-smoothing: antialiased;
}

.registration-team-search {
  position: sticky;
  top: 0;
  background: var(--tqc-grey);
  z-index: 10000;
  margin-top: -10px;
  padding-bottom: 10px;
}
