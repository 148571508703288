.tqn-payment-details-modal {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--tqc-grey);
    transition: filter 300ms;

    & .content {
        padding: 0 25px 20px 25px;
        flex: 1;
        overflow: auto;

        & .line {
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            width: 100%;

            & .label {
                min-width: 35%;
                display: flex;
                align-items: center;
                gap: 8px;
            }

            & .value {
                display: flex;
                align-items: center;
                justify-content: end;
                flex-grow: 1;
                gap: 6px;
            }
        }

        & .payment-item {
            border-radius: 12px;
            background: linear-gradient(
                    180deg,
                    var(--tqc-grey-1) 0%,
                    var(--tqc-grey-1) 100%
            );
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            padding: 15px 20px 20px 20px;

            & .event-title {
                display: flex;
                line-height: 22px;
                margin-bottom: 6px;
                font-weight: 500;
            }

            & .event-info {
                font-size: 14px;
                line-height: 24px;
                width: 100%;

                & .seat-info {
                    display: flex;
                    align-items: center;
                    gap: 7px;

                    & .inline-vertical-divider {
                        color: var(--tqc-grey-2);
                        font-weight: 500;
                    }
                }
            }

            & .home,
            & .away,
            & .at-symbol {
                font-size: 20px;
                font-weight: 600;
            }

            & .at-symbol {
                position: relative;
                top: -2px;
                margin: 0 6px;
            }
        }
    }

    & em {
        font-weight: 600;
    }
}