.tqn-account-nav-header {
  --tqc-nav-back: var(--tqc-green);

  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 20px 20px 20px;

  & .tqn-button {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;

    & button {
      font-weight: 400;
      text-decoration: none;
      display: flex;
      align-items: center;
      color: var(--tqc-nav-back);

      & span {
        margin-left: 2px;
      }
    }

    &.close {
      justify-self: end;
    }

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  & .title {
    font-weight: 600;
  }

  & .beta-badge {
    font-size: 12px;
    line-height: 17px;
    font-weight: 600;
    justify-self: end;
    border-radius: 25px;
    padding: 0 8px;
    background: var(--tqc-green);

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  }
}
