.tqn-screen {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  position: relative;
  background-color: var(--tqc-app-bg);

  & .tqn-screen-header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
    background-color: var(--tqc-app-bg);
    box-shadow: 0 6px 4px -4px var(--tqc-app-bg);
    overflow-x: hidden;
  }

  & .tqn-screen-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    background-color: var(--tqc-app-bg);
  }

  & .tqn-screen-content {
    background-color: var(--tqc-app-bg);
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    transition: margin-bottom 300ms;
    

    &.animating {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
}

.desktop .tqn-screen {
  position: relative;
  min-height: unset;
  height: 100%;

  & .tqn-screen-header {
    position: absolute;
    left: unset;
  }

  & .tqn-screen-content {
    height: 100%;
  }

  & .tqn-screen-content.overflow-hidden {
    overflow: hidden;
  }
}
