@keyframes pulse-orange {
    0% {
        box-shadow: 0 0 0 0 rgba(252, 176, 24, .7);
    }

    70% {
        box-shadow: 0 0 0 10px transparent;
    }

    100% {
        box-shadow: 0 0 0 0 transparent;
    }
}

.tqn-free-dashboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: -110px;

    .title {
        font-weight: bold;
        font-size: 32px;
        color: var(--tqc-gold);
        line-height: 1.4;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .line {
            display: flex;
            gap: 20px;

            .label {
                font-style: normal;
                font-weight: 600;
                font-size: 21px;
                color: var(--almost-white);
            }
        }
    }

    .divider {
        width: 50%;
        border-radius: 4px;
    }

    .get-started-btn {
        width: 78%;
        margin-top: 40px;

        button {
            animation: pulse-orange 1.5s infinite;
        }
    }
}