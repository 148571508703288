/* stylelint-disable no-descending-specificity */

.tqn-button {
  --tqc-button-grey: var(--tqc-grey-1);
  --tqc-button-lightgrey: var(--tqc-grey-3);
  --tqc-button-red: var(--tqc-red);
  --tqc-button-green: var(--tqc-green);
  --tqc-button-gold: var(--tqc-gold);
  --tqc-button-text: var(--tqc-white);
  --tqc-button-text-dark: var(--tqc-text-dark);
  --tqc-button-outlined-border: var(--tqc-grey-3);
  --tqc-button-outlined-text: var(--tqc-grey-5);
  --tqc-button-outlined-border-active: var(--tqc-grey-4);
  --tqc-button-outlined-bg-active: var(--tqc-grey-1);
  --tqc-button-gradient: linear-gradient(
    200.97deg,
    #c4935b -8.17%,
    #eccc86 47.6%,
    #bd8549 100%
  );

  display: block;
  position: relative;

  & button {
    width: 100%;
    height: 46px;
    text-align: center;
    color: var(--tqc-button-text);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
    padding: 0 16px;
    transition: background-color 200ms, color 200ms, border-color 200ms,
      opacity 300ms;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      opacity: 0.3;
      cursor: default;
    }

    & .arrow {
      display: inline-block;
      margin-left: 4px;
    }
  }

  & .loading-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.4);
    opacity: 0;
    transition: opacity 300ms;
    z-index: -1;
  }

  &.tqn-loading {
    & .loading-spinner {
      z-index: 1;
      opacity: 1;

      & div::after {
        background: var(--tqc-button-text);
      }
    }

    & button {
      opacity: 0.5;
    }
  }

  &.tqn-button-link {
    display: inline-block;

    & button {
      display: inline-block;
      width: auto;
      height: auto;
      padding: 0;
      font-size: 15px;

      & .btn-text {
        text-decoration: underline;
      }
    }

    &.tqn-button-noline button {
      & .btn-text {
        text-decoration: none;
      }
    }

    &.tqn-button-red button {
      color: var(--tqc-button-red);
    }

    &.tqn-button-green button {
      color: var(--tqc-button-green);
    }

    &.tqn-button-gold button {
      color: var(--tqc-button-gold);
    }

    &.tqn-button-grey button {
      color: var(--tqc-button-grey);
    }

    &.tqn-button-lightgrey button {
      color: var(--tqc-button-lightgrey);
    }
  }

  &.tqn-border-rounded button {
    border-radius: 25px;
  }

  &.tqn-button-gradient button {
    background: var(--tqc-button-gradient);
    color: var(--tqc-button-text-dark);

    &:active:not(:disabled) {
      opacity: 0.5;
    }
  }

  &.tqn-button-solid {
    & button {
      background-color: var(--tqc-button-lightgrey);
    }

    &.tqn-button-red button {
      background-color: var(--tqc-button-red);
    }

    &.tqn-button-green button {
      background-color: var(--tqc-button-green);
    }

    &.tqn-button-gold button {
      color: var(--tqc-button-text-dark);
      background-color: var(--tqc-button-gold);
    }

    &.tqn-button-grey button {
      background-color: var(--tqc-button-grey);
    }

    &.tqn-button-lightgrey button {
      background-color: var(--tqc-button-lightgrey);
    }
  }

  &.tqn-button-outlined {
    & button {
      border: 1px solid var(--tqc-button-outlined-border);
      color: var(--tqc-button-outlined-text);

      &:active:not(:disabled) {
        border-color: var(--tqc-button-outlined-border-active);
        background: var(--tqc-button-outlined-bg-active);
      }
    }

    &.tqn-highlighted button {
      border-color: var(--tqc-button-outlined-border-active);
      background: var(--tqc-button-outlined-bg-active);
    }

    &.tqn-button-red button {
      color: var(--tqc-button-red);
    }

    &.tqn-button-green button {
      color: var(--tqc-button-green);
    }

    &.tqn-button-gold button {
      color: var(--tqc-button-gold);
    }

    &.tqn-button-grey button {
      color: var(--tqc-button-grey);
    }

    &.tqn-button-lightgrey button {
      color: var(--tqc-button-lightgrey);
    }
  }

  & .btn-arrow {
    display: inline-block;
    margin-left: 2px;
    width: 14px;
    height: 14px;
  }
}
