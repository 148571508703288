.tqn-inventory-modal {
  background-color: rgba(16, 17, 17, 0.7);
  display: flex;
  flex-direction: column;
  top: 0;
  height: 100%;

  & .modal-content {
    padding: 24px 16px;
    flex: 1;
    overflow: auto;
    position: relative;

    & .select-label {
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 18px;
      font-weight: 400;
    }

    & .seats-row {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      margin: 15px auto 20px auto;
      max-width: 320px;
    }
  }
}
