.tqn-seat {
  display: flex;
  flex-direction: column;
  align-items: center;

  --seat-grey-color: var(--tqc-grey-4);

  & .seat-icon {
    width: 70px;
    height: 70px;
    border: 1.5px solid var(--seat-grey-color);
    border-radius: 11px;
    cursor: pointer;

    &::before {
      content: ' ';
      display: block;
      height: 100%;
      background-color: var(--seat-grey-color);
      mask-image: url(/images/icons/Icon-Chair.svg);
      mask-size: 50%;
      mask-position: center;
      mask-repeat: no-repeat;
    }
  }

  & .seat-number {
    font-size: 18px;
    font-weight: 600;
    color: var(--seat-grey-color);
    text-align: center;
    margin-top: 6px;
  }

  &.listed {
    & .seat-icon {
      border-color: var(--tqc-list-action);
      border-width: 2.5px;

      &::before {
        background-color: var(--tqc-list-action);
      }
    }

    & .seat-number {
      color: var(--tqc-list-action);
    }
  }

  &.delisted {
    & .seat-icon {
      border-color: var(--tqc-delist-action);
      border-width: 2.5px;

      &::before {
        background-color: var(--tqc-delist-action);
      }
    }

    & .seat-number {
      color: var(--tqc-delist-action);
    }
  }
}
