.tqn-add-btn.tqn-button-outlined {
  width: 100%;

  & button {
    border: 1px solid var(--tqc-horizontal-line-light);
    color: var(--tqc-text-light);
    border-radius: 25px;

    & .btn-label {
      padding-left: 10px;
    }
  }
}
