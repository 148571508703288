.tqn-page-waiting {
  --tqc-waiting-header: var(--tqc-gold);
  --tqc-waiting-text: var(--tqc-white);
  --tqc-waiting-footer: var(--tqc-grey-5);
  --tqc-waiting-subtitle: var(--tqc-grey-5);

  flex: 1;
  width: 100%;
  height: 90%;
  overflow: hidden;
  background-image: url(/images/background_success.png);
  background-size: cover;

  & .message-overlay {
    width: 90%;
    padding: 40px 25px 50px 25px;
    margin: 0 50px;
    font-style: normal;
    background: rgba(19, 27, 28, 0.5);
    backdrop-filter: blur(5px);
    border-radius: 20px;
    margin-bottom: 50px;

    & .header {
      font-weight: 700;
      font-size: 35px;
      text-align: center;
      color: var(--tqc-waiting-header);
    }

    & .message {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      text-align: center;
      color: var(--tqc-waiting-text);

      & p:not(:first-child) {
        margin-top: 16px;
      }
    }

    & .footer {
      position: relative;
      top: -5px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: var(--tqc-waiting-footer);
    }

    & .subtle {
      margin-top: 16px;
      position: relative;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: var(--tqc-waiting-subtitle);
    }
  }

  & .logout {
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;
  }
}
