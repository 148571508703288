.tqn-loading-animation {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  transition: opacity 300ms ease-in-out;

  & .text {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
    width: var(--sinnerWidth);
  }
}
