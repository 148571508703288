.tqn-info-modal {
  --tqc-info-modal-text: var(--tqc-white);
  --tqc-info-modal-text-secondary: var(--tqc-grey-4);
  --tqc-info-modal-text-highlight: var(--tqc-gold);
  --tqc-info-modal-title: var(--tqc-gold);

  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 300ms ease-in-out;

  & .container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(0);
    background: rgba(13, 18, 18, 0.7);
  }

  & .modal-wrapper {
    background: var(--tqc-modal-bg);
    width: 87%;
    padding: 25px 0 35px;
    border-radius: 10px;
  }

  & .title {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: var(--tqc-info-modal-title);
    max-width: 275px;
    margin: 0 auto;
    line-height: 1.4;
  }

  & .actions {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    & > .tqn-button {
      width: 160px;
    }
  }

  & .content {
    max-height: 65vh;
    padding: 5px 25px;
    overflow-y: auto;
    overflow-x: hidden;
    color: var(--tqc-info-modal-text);
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    position: relative;

    & em {
      font-weight: 700;
    }
  }

  & .close-btn {
    position: absolute;
    right: -26px;
    top: -26px;
  }

  & .fixed-close-btn-area {
    display: flex;
    justify-content: end;
    padding: 0 10px;
    margin-top: -17px;
    margin-bottom: 7px;
  }

  & .fixed-close-btn {
    cursor: pointer;
    width: fit-content;
  }
}

.info-modal-playoff {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  & .btn-group {
    width: 65%;
    margin-top: 25px;
  }
}
