.tqn-landing {
  position: relative;
  width: 100%;
  flex: 1;
  overflow: hidden;
  background-image: url(/images/background_landing.jpeg);
  background-size: cover;
  background-position: center;

  & .text-logo {
    display: block;
    margin: 100px auto 0;
  }

  & .hero {
    position: absolute;
    top: 35%;
    transform: translateY(-50%);
    width: 100%;
  }

  & .hero-text {
    margin: 0 auto;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  & .hero-text-image {
    display: block;
    margin: -18px auto 0;
    width: 15rem;
  }

  & .footer {
    position: absolute;
    bottom: 40px;
    padding: 0 24px;
    width: 100%;
  }

  & .login {
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
    font-size: 15px;

    & > span {
      opacity: 0.5;
    }
  }
}

.account-deleted-modal-close-btn {
  position: absolute;
  top: -86px;
  right: -25px;
}
