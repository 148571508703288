@keyframes BannerfadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-120px);
  }

  60% {
    opacity: 0.5;
    transform: translateX(10px);
  }

  90% {
    transform: translateX(-2px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.tqn-team-creds-details {
  & .status {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.5;

    & .fade-in-left {
      animation: BannerfadeInLeft 0.75s;
    }

    & .text-gold {
      text-transform: capitalize;
    }
  }

  & .status-description {
    font-size: 16px;
    line-height: 1.5;

    & .bold {
      font-weight: 600;
    }

    & .first-time-submissions {
      font-size: 14px;
      line-height: 1.5;
      color: var(--tqc-grey-5);
    }
  }

  & .alert-title {
    font-weight: 600;
  }

  & .contact-info {
    display: flex;
    align-items: center;
    gap: 5px;

    & .bold {
      font-weight: 600;
    }

    & .value {
      color: var(--tqc-green);
    }
  }

  & .alert-text {
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }

  & .learn-more-btn {
    margin-left: 38px;

    button {
      font-weight: 400!important;
      font-size: 14px!important;
    }
  }
}

.tqn-credentials-pending-modal {
  & .title {
    font-size: 24px;
    max-width: 100%;
    text-transform: capitalize;
  }

  & .tqn-button {
    width: 190px;
    margin: 25px auto 0;
  }
}
