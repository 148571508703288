.tqn-otp-modal {
  & .otp-spinner {
    transform: scale(0.4);
    height: 40px;
    margin-bottom: 20px;
    opacity: 0;
    transition: opacity 300ms;
    display: none;

    &.show {
      opacity: 1;
      display: inline-block;
    }

    & div::after {
      background: var(--tqc-otp-spinner);
    }
  }

  & .content {
    height: 300px;
    display: flex;
    flex-direction: column;
  }

  & .actions {
    display: flex;
    gap: 10px;
    justify-content: center;

    & .cancel-btn {
      width: 40%;
    }

    & .send-btn {
      flex: 1;
    }
  }

  & .instructions,
  & .error {
    line-height: 1.5;
  }
}