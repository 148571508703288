.tqn-tax-information {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;

  & .gap-25 {
    gap: 25px;
  }

  & .edit-btn,
  & .cancel-btn {
    flex: 3;
  }

  & .submit-btn {
    margin-left: 10px;
    flex: 4;
  }

  & .form-view {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  & .form-container {
    flex: 1;
  }

  & .status-approved {
    color: var(--tqc-success);
  }

  & .status-submitted {
    color: var(--tqc-gold);
  }

  & .status-rejected {
    color: var(--tqc-error);
  }

  & .error-text {
    color: var(--tqc-error);
  }

  & .tax-status-and-actions {
    position: relative;
    width: 100%;
  }
}
