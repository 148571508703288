.tqn-seat-ticket-reactivation {
    & .league-img {
        width: 100%;
        border-radius: 12px;
    }

    & .header {
        margin-top: 20px;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
    }

    & .content {
        margin-top: 6px;
        font-size: 14px;
        line-height: 22px;
    }

    & .need-confirm {
        font-weight: 500;
    }

    & .hey-name {
        font-weight: 500;
    }

    & .reactivation-no-longer-needed-block {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;

        .btn-text {
            font-weight: 400!important;
            font-size: 16px!important;
        }
    }
}
