.tqn-personal-info {
  --tqc-sms-text: var(--tqc-grey-4);

  & .sms {
    text-align: center;
    color: var(--tqc-sms-text);
    margin-top: 15px;
  }

  & .tqn-submit-btn {
    margin-top: 40px;
  }

  & .agreement-checkbox {
    display: flex;
    justify-content: start;
    gap: 10px;
    margin-top: 20px;

    & .checkbox {
      margin-top: 4px;
    }

    & .btn-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  & .hp-fields {
    display: none;
  }

  & .header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    line-height: 1.4;
    font-weight: 600;
    font-size: 20px;
  }
}
