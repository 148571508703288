.tqn-ticket-types {
  --tqc-ticket-types-notice: var(--tqc-gold);
  --tqc-ticket-types-button-text: var(--tqc-white);
  --tqc-ticket-types-button-desc: var(--tqc-grey-4);

  padding: 10px 24px;
  overflow: hidden;

  & .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    margin: 0 auto;
  }

  & .notice {
    margin: 20px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--tqc-ticket-types-notice);
    font-size: 14px;
    font-weight: 200;
    line-height: 22px;
    width: 286px;
    text-align: center;

    & em {
      font-weight: 600;
      margin-left: 4px;
      margin-right: 3px;
    }
  }

  & .type-btn button {
    height: 70px;
    border-radius: 35px;
    color: var(--tqc-ticket-types-button-text);
    flex-direction: column;

    & .desc {
      color: var(--tqc-ticket-types-button-desc);
      font-size: 13px;
      font-weight: 400;
    }
  }

  & .single-game-message {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-top: 30px;
    opacity: 0;
    z-index: 1;
    position: absolute;
    width: calc(100% - 48px);
    display: none;
  }

  & .next-button {
    opacity: 0;
    z-index: -1;
  }
}
