.tqn-text {
  &.page-normal {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  &.ta-center {
    text-align: center;
  }
}
