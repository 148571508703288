.tqn-how-we-can-help {
    padding: 10px 25px;

    .title {
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        color: var(--almost-white);
    }

    .text {
        line-height: 1.4;
        text-align: center;
    }

    .selling-tickets-btn {
        font-weight: 600;
    }

    .close-btn {
        width: 50%;
        text-align: center;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .block {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}