.tqn-ticket {
  --tqc-ticket-bg: var(--tqc-grey-1);
  --tqc-ticket-type-label: var(--tqc-green);

  border-radius: 10px;
  background: var(--tqc-ticket-bg);
  position: relative;
  overflow: hidden;

  & .section {
    padding: 12px 24px;
  }

  & .support-message {
    padding-left: 24px;
    margin-bottom: 15px;

    .btn-text {
      font-size: 12px!important;
      font-weight: 400!important;
      line-height: 20px!important;
    }
  }

  & .ticket-status {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    & .supported {
      color: var(--tqc-green);
    }

    & .pending {
      color: var(--tqc-gold);
    }

    & .not-supported {
      color: var(--tqc-red);
    }
  }

  & .ticket-live-icon {
    width: auto;
    margin-bottom: 8px;
    background-color: none;
    height: 16px;
  }

  & .ticket-type {
    color: var(--tqc-ticket-type-label);
    font-weight: 400;
    font-size: 11px;
    margin-bottom: 8px;
  }

  & .team-name {
    font-weight: 600;
    font-size: 18px;
  }

  & .info-row {
    display: flex;
    justify-content: space-between;
  }

  & .info-block {
    color: var(--tqc-text-light);
    text-align: center;

    & .label {
      font-weight: 400;
      font-size: 12px;
      opacity: 0.8;
      margin-bottom: 8px;
    }

    & .value {
      font-weight: 600;
      font-size: 14px;
    }
  }

  & .side-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    background: var(--tqc-app-bg);

    &.left {
      transform: translate(-50%, -50%);
      left: 0;
    }

    &.right {
      transform: translate(50%, -50%);
      right: 0;
    }
  }

  & .edit-btn {
    border-bottom-left-radius: 10px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      opacity: 0.4;
    }
  }
}
