.tqn-seat-submission-modal {
  overflow-y: hidden;
  overflow-x: hidden;

  & .league-bg-img {
    width: 100%;
    filter: brightness(0.35);
    position: absolute;
    top: 65px;
    z-index: 250;
    padding: 0 24px;
  }

  & .tickets-container .tqn-ticket:not(:first-child) {
    margin-top: 20px;
  }

  & .tqn-screen-content {
    padding-top: 81px;
  }
}
