.tqn-my-season-tickets {
    & .seat-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        & .ticket-package {
            width: 100%;
        }
    }

    & .item-wrapper {
        position: relative;
        width: 100%;
        height: 0;
        transition: height 300ms ease-in-out, opacity 300ms ease-in-out;
        overflow: hidden;

        & .ticket-package {
            position: absolute;
            width: 100%;
        }
    }
}