.tqn-image-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;

  &.tqn-size-xs {
    width: 16px;
    height: 16px;
  }

  &.tqn-size-sm {
    width: 20px;
    height: 20px;
  }

  &.tqn-size-lg {
    width: 32px;
    height: 32px;
  }

  &.tqn-size-xl {
    width: 42px;
    height: 42px;
  }

&.tqn-size-xxl {
   width: 48px;
   height: 48px;
 }

  &.tqn-size-auto {
    width: auto;
    height: auto;
  }
}
