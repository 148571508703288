.info-modal-pricing-strategy {
  text-align: left;

  & .close-btn {
    float: right;
    position: sticky;
    margin-right: -10px;
    margin-top: -10px;
    cursor: pointer;
  }

  & ul {
    list-style: disc;

    & li {
      list-style-position: outside;
      margin-left: 1em;
    }

    & li::marker {
      color: var(--tqc-gold);
    }
  }
}

.info-modal-welcome {
  font-size: 16px;
  line-height: 24px;

  & .subtitle {
    font-size: 20px;
    font-weight: 600;
  }

  & .li-number {
    font-size: 16px;
    font-weight: 600;
    margin-right: 8px;
  }

  & .list-button {
    display: inline-block;
    width: 56px;
    height: 24px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    background: var(--tqc-list-action);
    border-radius: 12px;
    line-height: 24px;
    margin-left: 2px;
  }

  & .instructions {
    margin-top: 10px;
    margin-bottom: 40px;
    line-height: 1.7;

    & li {
      display: flex;
      text-align: left;
      justify-content: center;
    }
  }
}

.info-modal-first-listing {
  & .text {
    color: var(--tqc-info-modal-text-secondary);
    font-size: 12px;
  }
}

.info-modal-delete-alert {
  padding-top: 15px;
  position: relative;

  & .text {
    & span {
      display: inline;
    }
  }

  & .heading {
    max-width: 220px;
    margin: 0 auto;
  }

  & .description {
    max-width: 248px;
    margin: 15px auto 0;
    color: var(--tqc-info-modal-text-secondary);
  }

  & .again-confirmation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 24px;

    & label {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.info-first-sale {
  padding-top: 70px;
  position: relative;

  & .first-sale-img {
    width: auto;
    height: 140px;
    position: absolute;
    top: -70px;
    transform: translateX(-50%);
  }

  & .text-md-light {
    margin-left: -12px;
    margin-right: -12px;
  }

  & .info-fields {
    text-align: center;
    color: var(--tqc-info-modal-text-highlight);
  }

  & .arrow-icon {
    width: 14px;
    height: 14px;
    margin-bottom: 2px;
    margin-left: 4px;
  }
}

.info-modal-welcome-back,
.info-modal-w9-form {
  & .text-sm-light {
    color: var(--tqc-info-modal-text-secondary);
  }
}

.info-modal-playoff .message em {
  font-weight: 600;
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.tqn-delete-account {
  & .tqn-button {
    width: 204px;
    margin-left: auto;
    margin-right: auto;
  }
}

.info-modal-no-longer-have-ticket {
  .content {
    font-weight: 400;
  }
}

.info-modal-listing-policy-alert {
  position: relative;

  & .title {
    font-weight: 700;
    font-size: 28px;
    color: var(--tqc-gold);
    max-width: 275px;
    margin: 0 auto;
  }

  & .checkbox-section {
    display: flex;
    justify-content: start;
    gap: 20px;
    margin-top: 24px;

    .content-checkbox {
      margin-top: 4px;
    }

    .content-block {
      text-align: start;
      cursor: pointer;
    }

    & header {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      padding-bottom: 6px;
    }

    & ul {
      list-style: disc;

      & li {
        list-style-position: outside;
        margin-left: 1em;
      }

      & li::marker {
        color: var(--tqc-gold);
      }
    }
  }
}

.info-modal-minimum-games {
  .content-body {
    font-weight: 400;
    text-align: start;
  }
}

.info-modal-fee-split, .info-modal-pricing-strategy {
  text-align: left;

  & > div {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & .close-btn {
    top: -15px;
    right: -15px;
    cursor: pointer;
  }
}

.how-did-you-find-us {
  & .tqn-multi-select {
    width: fit-content;
    margin: 0 auto;
  }
}

.info-modal-delist-sale-guarantee {
  & .actions {
    gap: 10px;

    & .submit {
      width: 70%;
    }
  }
}