html, body {
  margin: 0;
}

div {
  box-sizing: border-box;
}

input {
  all: unset;
  box-sizing: border-box;
}

button {
  all: unset;
  box-sizing: border-box;
}

input:-internal-autofill-selected {
  background-color: var(--input-bg);
}

input:-webkit-outer-spin-button,
input:-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--input-text) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--input-bg) inset !important;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
