.tqn-verification-details {
    & .alert-text {
        display: flex;
        font-size: 16px;
        align-items: center;
    }

    & .header {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.4;
    }

    & .text {
        font-size: 16px;
        line-height: 1.4;
        margin-bottom: 15px;
    }

    ul {
        list-style: disc;
        padding: 0 15px;
        margin-bottom: 20px;

        li:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    & .text-row {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    & .contact-info {
        --tqc-highlighted-value: var(--tqc-green);

        & .tqn-accounts-menu-item.phone {
            .value {
                color: var(--tqc-highlighted-value);
                text-decoration: underline;
            }
        }
    }
}