.tqn-num-of-games {
  --tqc-input: var(--tqc-green);

  flex: 1;
  padding: 10px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .inner-content {
    flex: 1;
    margin-top: 40px;
  }

  & .number-field {
    padding: 0;
    width: 2em;
    margin: 24px auto 0;
    font-size: 48px;
    font-weight: 400;
    line-height: 20px;

    & input {
      padding: 0;
      color: var(--tqc-input);
      text-align: center;
      border-bottom: none;
      letter-spacing: 0.2em;
    }

    &.tqn-input__active input {
      border-bottom: none;
    }
  }

  & .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    margin: 0 auto;
  }

  & .actions {
    width: 100%;
    bottom: 80px;
    position: absolute;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.keyboard-showing .tqn-num-of-games {
  & .tqn-continue-btn {
    bottom: unset;
    position: relative;
  }

  & .tqn-slider {
    opacity: 0;
  }
}
