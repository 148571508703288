.tqn-header {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 22px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 12px;
  background-color: var(--bg);

  & .back-btn {
    display: flex;
  }

  & .tqn-title {
    font-size: 20px;
    font-weight: 600;
  }
}
