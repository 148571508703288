@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('/fonts/Poppins-Light.ttf') format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Poppins-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/Poppins-Medium.ttf') format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/Poppins-SemiBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/Poppins-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('/fonts/Poppins-ExtraBold.ttf') format('opentype');
}
