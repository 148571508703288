.tqn-first-loading {
    position: relative;
    width: 100%;
    flex: 1;
    overflow: hidden;
    background-image: url(/images/background_landing.jpeg);
    background-size: cover;
    background-position: center;

    .lds-spinner {
        position: absolute;
        left: 50%;
        top: 50%;
    }
}