.tqn-password-input {
  --tqc-toggle-btn: var(--tqc-grey-3);

  position: relative;

  & .icon {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: -4px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
