.tqn-dropdown-selector {
  display: flex;
  align-items: center;
  border: 1px solid var(--tqc-horizontal-line-light);
  border-radius: 15px;
  padding: 3px 8px;
  cursor: pointer;
  transition: opacity 300ms ease-in;
  outline: none;

  & span {
    flex: 1;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 22px;
    height: 22px;
  }

  & .tqn-image-icon {
    flex: none;
  }

  &.hidden {
    opacity: 0;
  }
}
