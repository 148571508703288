.tqn-ticket-league-group {
    --tqc-ticket-bg: var(--tqc-grey-1);

    position: relative;
    overflow: hidden;
    padding: 20px 20px 15px;

    &.fill {
        border-radius: 10px;
        background: var(--tqc-ticket-bg);
    }

    .header {
        font-size: 24px;
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 6px;
    }

    .menu-label {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
    }

    .menu-icon {
        width: 28px;
        height: 28px;
    }
}