.tqn-accounts {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  & .referral-program-section {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--tqc-grey-3);
    border-radius: 10px;
    padding: 9px 0 6px 0;
    gap: 2px;

    & em {
      font-weight: 600;
    }
  }

  & .social-media-section {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    & .tqn-image-icon {
      max-width: 44px;
      max-height: 44px;
      cursor: pointer;
    }
  }
}