/*
     Transitions on a linear-gradient background property
     do not currently work (I only verified in chrome, but this was
     stated on several blog posts). So, I had to create a separate
     DOM element and transition that.

     solution source:
     https://keithjgrant.com/posts/2017/07/transitioning-gradients/

     -aaron 04/12/2022
  */

.tqn-domain-button {
  --pressed-bg: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 65.1%,
      rgba(0, 0, 0, 0.24) 100%
    ),
    linear-gradient(90deg, #e0ba69 0%, #bd8240 100%);
  --unpressed-bg: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 65%,
      rgba(0, 0, 0, 0.15) 85%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    linear-gradient(135deg, var(--tqc-grey-3), #1e2121);
  --radius: 10px;

  z-index: 1;
  user-select: none;
  display: flex;
  position: relative;
  background: var(--unpressed-bg);
  border-radius: var(--radius);

  &:not(:first-child) {
    margin-top: 20px;
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--pressed-bg);
    border-radius: var(--radius);
    z-index: -1;
    transition: opacity 0.1s linear;
    opacity: 0;
  }

  &:active::before,
  &.pressed::before {
    opacity: 1;
  }
}

.tqn-domain-button.league-button {
  overflow: hidden;

  & .text {
    &::before {
      display: inline-block;
      height: 100%;
      content: '';
    }

    color: var(--tqc-text-dark);
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 800;
    font-size: 66px;
    margin-left: 24px;
  }

  & img {
    transition: mix-blend-mode 0.1s linear;
    position: absolute;
    width: 100px;
    right: 20px;
    top: 7px;
    filter: grayscale(30%);
  }

  &.pressed img,
  &:active img {
    mix-blend-mode: hard-light;
  }
}

.tqn-domain-button.team-button {
  justify-content: flex-end;

  & .abbrev {
    &::before {
      display: inline-block;
      height: 100%;
      content: '';
    }

    color: var(--tqc-text-dark);
    display: inline-block;
    font-style: normal;
    font-weight: 800;
    font-size: 80px;
    margin-right: 22px;
  }

  & .name-frame {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    & > .name {
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      color: var(--almost-white);
      margin-left: 30px;
    }
  }
}
