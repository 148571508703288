.tqn-ticket-actionsheet {
  --tqc-actionsheet-text: var(--tqc-white);
  --tqc-actionsheet-header-text: var(--tqc-gold);
  --tqc-actionsheet-close: var(--tqc-grey-3);

  text-align: center;
  font-weight: 200;
  padding: 30px 30px 30px 30px;

  & .title {
    font-weight: 700;
    font-size: 28px;
    text-align: center;
    color: var(--tqc-actionsheet-text);
    max-width: 275px;
    margin: 0 auto;
  }

  & .section {
    margin-bottom: 32px;
  }

  & .header {
    font-size: 20px;
    font-weight: 600;
    color: var(--tqc-actionsheet-header-text);
    margin-bottom: 10px;
  }

  & .body {
    font-weight: light;

    & p {
      line-height: 24px;
      font-weight: 400;
    }

    & p:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  & .highlights {
    font-size: 16px;
    font-weight: 600 !important;
    color: var(--tqc-actionsheet-text);
    margin: 0 4px;
  }

  & i {
    font-style: italic;
  }

  & .close-btn {
    width: 160px;
    margin: 0 auto;

    & button {
      background-color: var(--tqc-actionsheet-close) !important;
    }
  }
}
