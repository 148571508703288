.tqn-input {
  --tqc-input-text: var(--tqc-white);
  --tqc-input-required: var(--tqc-green);
  --tqc-input-active-border: var(--tqc-gold);
  --tqc-input-error: var(--tqc-red);

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--tqc-input-text);
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;

  & .tqn-input__label {
    position: absolute;
    transition: transform 150ms;
    transform-origin: top left;
    transform: scale(0.75) translateY(-28px);

    & span {
      opacity: 0.7;
      transition: opacity 150ms;
      white-space: nowrap;
    }

    & .tqn-input__required {
      color: var(--tqc-input-required);
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      display: inline-block;
      margin-left: 4px;
    }
  }

  & input {
    width: 100%;
    min-height: 34px;
    appearance: none;
    margin: 0;
    padding: 0 0 8px 0;
    border-bottom: 1.5px solid var(--tqc-horizontal-line-light);
  }

   input::after {
    content: '123123';
  }

  &.tqn-input__empty .tqn-input__label {
    transform: scale(1) translateY(2px);

    & span {
      opacity: 0.65;
    }
  }

  &.placeholder {
    & input {
      color: rgba(255, 255, 255, 0.3);
    }
  }

  &.tqn-input__active {
    & .tqn-input__label {
      transform: scale(0.75) translateY(-28px);

      & span {
        opacity: 0.8;
      }
    }

    & input {
      border-color: var(--tqc-input-active-border);
    }
  }

  &.tqn-input__error {
    & input {
      border-color: var(--tqc-input-error);
    }
  }

  & .tqn-error {
    position: absolute;
    top: 54px;
    font-weight: 400;
    font-size: 11px;
    color: var(--tqc-input-error);
  }

  & .input-row {
    display: flex;
    align-items: start;

    & .input-prefix {
      padding: 2px 8px 0 0;
    }
  }

  & .w-100 {
    width: 100%;
  }
}
